// Pasted from firestore console
const firebaseConfig = {
  apiKey: 'AIzaSyCvnA7FG-OaOmCwn-RQav9bjSRh-uvxWxU',
  authDomain: 'voyd-center.firebaseapp.com',
  projectId: 'voyd-center',
  storageBucket: 'voyd-center.appspot.com',
  messagingSenderId: '1037564319307',
  appId: '1:1037564319307:web:eb4be3636dde7151419fce',
  measurementId: 'G-HJL9RLN2CB', // For Firebase JS SDK v7.20.0 and later, measurementId is optional
}

export default firebaseConfig
