import toast from 'react-hot-toast'
import config from 'common/config'

// Manages communication with our own browser extension (if installed)

// To make this as cross-browser compatible as possible, we use normal postMessage functionality to send messages from web to
// the extension's content_script, which then proxies the message on to the background script.
//
// (Chrome supports messaging the extension directly if we have the extension ID, but it's unclear if that will work with firefox)
// https://stackoverflow.com/questions/11431337/sending-message-to-chrome-extension-from-a-web-page
import { WEB_TO_EXT_PROXY_MESSAGE_TYPE } from 'common/messaging'

let globalMsgId = 1 // start truthy

const defaultAskOptions = {
  timeout: 3000,
}

// action: must match one of the defined actions in extension's background/callableActions.js
// arg: actions currently accept one optional (object) argument.. can revisit if more needed, but simpler than checking arity on the extension side
// returns a promise that resolves with the return value from the extension
export const askExtensionTo = (action, arg, opts) => {
  if (!window) return Promise.reject

  const options = Object.assign(opts || {}, defaultAskOptions)

  return new Promise((resolve, reject) => {
    const thisMsgId = ++globalMsgId
    let timeoutRef

    const responseHandler = (resp) => {
      if (!resp?.data) return

      // Ignore our outgoing message
      if (resp.data.type === WEB_TO_EXT_PROXY_MESSAGE_TYPE) return

      if (resp.data.__msgId === thisMsgId) {
        window.removeEventListener('message', responseHandler)
        clearTimeout(timeoutRef)

        if (resp.data.error) {
          if (resp.data.error.message === 'Extension context invalidated.') {
            toast.custom(
              <div className="bg-white bg-red-200 p-4 rounded text-red-900 max-w-sm">
                Lost connection to the {config.appName} browser extension --
                please{' '}
                <a
                  href="#"
                  onClick={() => {
                    toast.dismiss()
                    window.location.reload()
                  }}
                  className="text-red-600 hover:text-red-800"
                >
                  refresh the page
                </a>{' '}
                to re-establish connectivity.
              </div>,
              { duration: 50000 },
            )

            resolve(false)
          }
          reject(resp.data.error)
        } else {
          // console.log('[askExtensionTo] response:', resp.data)
          resolve(resp.data.payload)
        }
      }
    }

    window.addEventListener('message', responseHandler, false)

    window.postMessage(
      {
        type: WEB_TO_EXT_PROXY_MESSAGE_TYPE,
        payload: { action, arg },
        __msgId: thisMsgId,
      },
      window.location.origin, // attempting to reach extension's content script, which is running in our own page
    )

    timeoutRef = setTimeout(() => {
      // console.warn('[TIMED OUT] Attempt to ask extension failed:', action, arg)
      window.removeEventListener('message', responseHandler)
      reject('Request timed out')
    }, options.timeout)
  })
}
