import UniversalLink from 'universal/UniversalLink'
import { LogoWithTitle } from 'cc/Logo'
import routes from 'common/routes'

const NavLogo = ({ dark, className }) => {
  return (
    <UniversalLink href={routes.rootUrl} className="hover:opacity-80">
      <LogoWithTitle className={className || 'w-28'} />
    </UniversalLink>
  )
}

export default NavLogo
