import ErrorBoundary from 'cc/ErrorBoundary'
import Head from 'next/head'
import TopNavBar from './TopNavBar'
import DefaultFooter from './DefaultFooter'
import { cn } from 'common/utils'
import React, { useRef } from 'react'

const DefaultLayout = ({ Component, pageProps }) => {
  const {
    title,
    subtitle,
    fullscreen = false,
    fullWidthFooter = false,
    domainLogos = false,
  } = Component

  const headerRef = useRef(null)

  const contentClasses = cn('md:mx-auto', fullscreen || 'md:container')
  return (
    <>
      <Head>
        {false && <link rel="icon" href="/cloud-edit.svg" />}
        {false && (
          // TODO: https://medium.com/swlh/are-you-using-svg-favicons-yet-a-guide-for-modern-browsers-836a6aace3df
          <>
            <meta name="theme-color" content="#ffffff" />
            <link rel="mask-icon" href="mask-icon.svg" color="#000000" />
            <link rel="apple-touch-icon" href="apple-touch-icon.png" />
            <link rel="manifest" href="manifest.json" />
          </>
        )}
      </Head>
      <TopNavBar ref={headerRef} className={cn('sticky w-full')} />
      <div
        className={cn(
          'h-screen overflow-auto',
          'bg-gradient-to-b from-gray-100 to-gray-700',
        )}
      >
        <div
          className={cn(
            'h-full w-full bg-gradient-to-b from-gray-100 to-gray-300',
          )}
        >
          <main className={cn('pt-4 pb-16 lg:pb-20 xl:pb-24 min-h-screen')}>
            {title || subtitle ? (
              <div className={cn(contentClasses, 'px-4 py-2 md:pt-5')}>
                {title && <h2 className="text-2xl md:text-3xl ">{title}</h2>}
                {subtitle && (
                  <h3 className="text-xl md:text-2xl text-gray-400 font-thin py-2">
                    {subtitle}
                  </h3>
                )}
              </div>
            ) : null}
            <div className={cn('k-wrapper pb-4', contentClasses, 'px-4')}>
              <ErrorBoundary>
                <Component {...pageProps} />
              </ErrorBoundary>
            </div>
          </main>

          <DefaultFooter
            className={cn(
              '-mt-16 lg:-mt-18 xl:-mt-24',
              'bg-gradient-to-tr from-gray-400 to-gray-50 border border-gray-400/75',
              fullWidthFooter
                ? 'w-full border-t border-gray-600'
                : 'md:mx-10 md:rounded-t',
            )}
            domainLogos={domainLogos}
          />
        </div>
      </div>
    </>
  )
}

export default DefaultLayout
