import { getCollection } from 'utils/swrFirestore'

const getUserFollowingRecords = async (uid) => {
  const followings = await getCollection(uid ? 'followings' : null, {
    where: ['follower', '==', uid],
  })

  return followings
}

export default getUserFollowingRecords
