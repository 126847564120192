// User following
export const getFollowKey = (currentUserUid, maybeFollowedUid) => [currentUserUid, maybeFollowedUid].join(':')

export const getBlockKey = (currentUserUid, maybeBlockedUid) => [currentUserUid, maybeBlockedUid].join(':')

import getUserFollowingRecords from 'universal/getUserFollowingRecords'

export const getFollowedUserTagsByUid = async (uid) => {
  const followings = (await getUserFollowingRecords(uid)) || []

  const taggedByUid = followings.reduce((byUid, { followed, tagged = [] }) => {
    byUid[followed] = tagged
    return byUid
  }, {})

  return taggedByUid
}
