import { authAtom, useRecoilValueLoadable } from 'common/recoil'
import { useCallback, useEffect, useState } from 'react'

export { getUserById } from 'utils/hooks'

export const useCurrentUser = () => {
  const [user, setUser] = useState(undefined)

  const { state, contents } = useRecoilValueLoadable(authAtom)
  useEffect(() => {
    if (state === 'hasValue') {
      setUser(contents)
    }
  }, [state, contents])

  return user
}
export const useCurrentUserId = () => useCurrentUser()?.id

export const useRefetchCurrentDomainPacket = () => {
  const refresher = useCallback(async () => {
    console.log(
      '[useRefetchCurrentDomainPacket] Ext would reload the domain packet',
    )
  }, [])

  return refresher
}
