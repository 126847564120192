import {
  getCollection,
  useCollection,
  getDocument,
  useDocument,
} from 'utils/swrFirestore/core'
import { onlyExisting } from 'utils/swrFirestore'

import {
  makeCollectionTopGetter,
  getReviewDocument,
  getReviewCollection,
} from './helpers'

import { getFollowKey } from 'common/api/helpers'
import { uniq, compact } from 'lodash'
import { SignalHandleIsUidPrefix } from 'common/routes'
import { useState, useEffect } from 'react'
import { useHasMounted } from 'common/hooks'

export const getUsersById = async (uids) =>
  onlyExisting(await Promise.all(uids.map(getUserById)))

export const getUserById = (userId) =>
  getDocument(userId ? `users/${userId}` : null, {
    parseDates: [
      'meta.lastDomainReviewAt',
      'meta.lastPageReviewAt',
      'meta.lastReviewedAt', // deprecated
      'meta.deletedAt',
    ],
  })

const userDates = [
  'meta.deletedAt',
  'meta.lastReviewedAt', // Deprecated
  'meta.lastDomainReviewAt',
  'meta.lastPageReviewAt',
]

export const getMostFollowedUsers = makeCollectionTopGetter(
  'users',
  'meta.numFollowers',
  { parseDates: userDates },
)
// export const getMostProlificUsers = makeCollectionTopGetter(
//   'users',
//   'meta.numDomainReviews',
//   { parseDates: userDates },
// )

export const getUsersForCollection = async (collection) => {
  if (!collection?.length) return []
  const uids = uniq(compact(collection.map((review) => review.meta.userId)))

  return Promise.all(uids.map(getUserById))
}

export const useUserById = (userId, opts) =>
  useDocument(userId ? `users/${userId}` : null, opts, {
    parseDates: userDates,
  })

export const getUserByHandle = async (handle) => {
  const users = await getCollection(
    handle ? 'users' : null,
    {
      where: ['handle', '==', handle],
      limit: 1,
    },
    { parseDates: userDates },
  )

  return users?.length ? users[0] : null
}

export const getUser = (input) => {
  const isUid =
    input?.slice(0, SignalHandleIsUidPrefix.length) === SignalHandleIsUidPrefix

  return isUid
    ? getUserById(input.slice(SignalHandleIsUidPrefix.length))
    : getUserByHandle(input)
}

export const getUserProfile = (userId) =>
  getDocument(userId ? `users/${userId}/extraUserData/profile` : null)

export const useProfileReviewForUser = (userId) => {
  const [review, setReview] = useState(null)
  const hasMounted = useHasMounted()

  useEffect(() => {
    const getReview = async () => {
      if (!userId || !hasMounted) return

      const profile =
        (await _getUsersProfileReview(userId)) ||
        (await _getUsersFirstReview(userId))

      setReview(profile)
    }

    getReview()
  }, [userId, hasMounted])

  return review
}

const _getUsersProfileReview = async (uid) =>
  await getReviewDocument(
    uid ? `users/${uid}/extraUserData/profileReview` : null,
  )

export const getProfileReviewForUser = async (uid) =>
  (await _getUsersProfileReview(uid)) || (await _getUsersFirstReview(uid))

const _getUsersFirstReview = async (uid) => {
  const reviews = await getReviewCollection(uid ? 'domainReviews' : null, {
    // where: [firestore.FieldPath.documentId(), '==', uid], // TODO: Gah! This is the irritating "FirebaseError: Invalid query. When querying a collection group by FieldPath.documentId(), the value provided must result in a valid document path, but '<user_id>' is not because it has an odd number of segments (1).""
    where: ['meta.userId', '==', uid],
    orderBy: ['meta.createdAt', 'asc'],
    isCollectionGroup: true,
    limit: 1,
  })

  return reviews ? reviews[0] : null
}

const getBlockedBy = async (uid) =>
  getCollection(uid ? 'blockings' : null, {
    where: ['blocker', '==', uid],
  })

// export const useRecentUserDomainReviews = ({
//   uid,
//   limit = 20,
//   listen = false,
// }) =>
//   useCollection(
//     uid ? 'domainReviews' : null,
//     {
//       // where: [firestore.FieldPath.documentId(), '==', uid], // TODO: Gah! This is the irritating "FirebaseError: Invalid query. When querying a collection group by FieldPath.documentId(), the value provided must result in a valid document path, but '<user_id>' is not because it has an odd number of segments (1).""
//       where: ['meta.userId', '==', uid],
//       orderBy: ['meta.createdAt', 'desc'],
//       isCollectionGroup: true,
//       limit,
//     },
//     {
//       listen,
//     },
//   )

// Basically just making sure we always have a tagged array when we read
const normalizeFollowingRecord = (raw) => {
  const { followed, follower, meta = {}, tagged = [] } = raw || {}

  return {
    followed,
    follower,
    meta,
    tagged,
  }
}

export const useDoesFollow = ({
  uid,
  toBeFollowedUid,
  listen = false,
} = {}) => {
  const hasMounted = useHasMounted()
  const { data } = useDocument(
    !hasMounted || !uid || !toBeFollowedUid
      ? null
      : `followings/${getFollowKey(uid, toBeFollowedUid)}`,
    {
      parseDates: ['meta.timestamp'],
      listen,
    },
  )

  // As long as this returns undefined, we don't have data yet
  if (data === undefined) return data

  // Note: a layer of logic here over what swr-firestore returns directly
  return data?.exists ? normalizeFollowingRecord(data) : false
}

// add return keys for:
//  - just followed / follower UIDs
//  - a lookup hash from uid to timestamp
const useWrappedFollowingResults = ({ data, loading, ...result }, key, uid) => {
  const [returningData, setReturningData] = useState({ loading: true })

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const loadData = async () => {
      if (loading || !data)
        return { data, loading, ...result, uids: [], timestampsByUid: {} }

      const forUserDoingTheFollowing = key === 'followed'

      // If I'm following you, I should be able to see how I tagged the following. You should NOT be able to see this.
      const parsedData = normalizeFollowingRecord(
        forUserDoingTheFollowing ? data : data.map(({ tagged, ...d }) => d),
      )

      const returning = {
        data: parsedData,
        ...result,
        uids: data?.map((f) => f[key]),
        timestampsByUid: data?.reduce((acc, elem) => {
          acc[elem[key]] = elem.meta?.timestamp
          return acc
        }, {}),
      }

      if (forUserDoingTheFollowing) {
        returning.taggedByUid = data?.reduce((acc, elem) => {
          acc[elem[key]] = elem.tagged
          return acc
        }, {})
      } else {
        const blockings = await getBlockedBy(uid)
        returning.blockedByUid = blockings?.reduce((acc, elem) => {
          acc[elem.blocked] = elem.meta?.timestamp || true
          return acc
        }, {})
      }

      setReturningData(returning)
    }

    loadData()
  }, [data, uid, loading])

  return returningData
}

// Uids of users *who are followed by* a given UID
export const useUidsUserFollowedBy = (uid) =>
  useWrappedFollowingResults(
    useCollection(uid ? 'followings' : null, {
      where: ['followed', '==', uid],
    }),
    'follower',
    uid,
  )

// Uids of users *who are following* given UID
export const useUidsUserIsFollowing = (uid) =>
  useWrappedFollowingResults(
    useCollection(uid ? 'followings' : null, {
      where: ['follower', '==', uid],
    }),
    'followed',
    uid,
  )

// Uids of users *who are blocked by* given UID
export const useUidsUserIsBlocking = (uid) =>
  useWrappedFollowingResults(
    useCollection(uid ? 'blockings' : null, {
      where: ['blocker', '==', uid],
    }),
    'blocked',
    uid,
  )

const normalizeReviewCounts = (rawReviewCounts = {}) => {
  const { numPositive = 0, numNegative = 0, numTotal = 0 } = rawReviewCounts

  const reviewCounts = {
    '-1': numNegative,
    0: numTotal - numPositive - numNegative,
    1: numPositive,
  }

  return reviewCounts
}

export const getDomainReviewCounts = async (domainKey, firestore) => {
  // NOTE: have to use admin keys here - only load on server side
  const reviewCountsData = (
    await firestore
      .collection('~privateDomains')
      .doc(domainKey)
      .collection('system')
      .doc('reviewsReceived')
      .get()
  ).data()

  return normalizeReviewCounts(reviewCountsData)
}

export const getUserProfileReviewCounts = async (userId) => {
  if (!userId) return null

  const rawReviewCounts = await getDocument(
    userId ? `users/${userId}/extraUserData/reviewsLeft` : null,
  )

  return normalizeReviewCounts(rawReviewCounts)
}

export const useUserReviewCounts = (uid) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    if (!uid) return
    const getData = async () => {
      const d = await getUserProfileReviewCounts(uid)
      setData(d)
    }
    getData()
  }, [uid])

  return data
}
