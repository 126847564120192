import { lowerFirst, trim, deburr } from 'lodash'
import camelCase from 'camelcase' // Note: If you need to support Firefox, stay on version 5 as version 6 uses regex features not available in Firefox.

export const MIN_HASHTAG_LENGTH = 3
export const MAX_HASHTAG_LENGTH = 64

export const stringToHashtag = (string = '') => {
  const base = trim(string)
  if (base.length < MIN_HASHTAG_LENGTH) return ''
  if (base.length > MAX_HASHTAG_LENGTH) return ''

  return camelCase(
    trim(
      deburr(base).replace(/[^\w\s]/g, ' '), // Caution - strips out non-ASCII (although deburr helps). Revisit when INTL becomes a thing.
    ),
    {
      pascalCase: true,
      preserveConsecutiveUppercase: true,
    },
  )
}

const capitalLetters = /([A-Z])/g

export const hashtagToString = (tag, separator = '-') => {
  // Note: misses case where first-and-second should both be capitalized, but nothing else
  const downcased = (
    tag[1].match(capitalLetters) ? tag : lowerFirst(tag)
  ).replace(capitalLetters, ($0) => [separator, $0.toLowerCase()].join(''))

  return trim(downcased, [separator])
}
