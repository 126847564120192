export * from './ExternalDomainLink'

import { cn } from 'common/utils'
import NextLink from 'next/link'
import React from 'react'

// NOTE: this used to add onClick support... not sure there's much point in this intermediate layer anymore
const Link = React.forwardRef(({ text, children, ...props }, ref) => (
  <NextLink {...props} ref={ref}>
    {text || children}
  </NextLink>
))
Link.displayName = 'Link'

export const SiteLink = ({
  className = 'text-gray-500 hover:text-gray-700 underline',
  ...props
}) => <Link {...props} className={className} />

export const FOOTER_LINK_CLASS_NAMES =
  'transition-colors duration-200 text-gray-600 hover:text-purple-800'

export const FooterLink = ({ className, ...props }) => (
  <Link {...props} className={cn(className, FOOTER_LINK_CLASS_NAMES)} />
)

export default Link
