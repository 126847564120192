// TODO: add support for link: true?
export const handle = (user, includeDisplay = false) => {
  if (!user) return '[anonymous user]' // todo: sure we want this default?
  if (user.handle) return includeDisplay && user.name ? `@${user.handle} (${user.name})` : `@${user.handle}`
  if (user.name) return user.name
  return '[unknown user]'
}

const asDate = (date) => {
  if (date instanceof Date) return date
  return new Date(date)
}

const makeDate = (object, key) => {
  // TODO: no idea what this eslint ignor is talking about
  if (!object.hasOwnProperty(key)) return // eslint-disable-line no-prototype-builtins
  if (object[key] instanceof Date) return

  object[key] = asDate(object[key])
}

// TODO: ideally should handle user from algolia OR from firestore directly... right now it's just from algolia hits
export const normalizeUser = (raw) => {
  const u = Object.assign({}, raw)

  if (u.meta) {
    makeDate(u.meta, 'createdAt')
    makeDate(u.meta, 'updatedAt')
    makeDate(u.meta, 'lastDomainReviewAt')
    makeDate(u.meta, 'lastReviewedAt')
    makeDate(u, 'lastmodified')
  }

  return u
}
