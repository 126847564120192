import { getCollection, getDocument } from 'utils/swrFirestore'

import { keyBy } from 'lodash'
import { buildDomainPacket } from 'common/utils'

const getDomainPageReviews = async ({ domainKey, userId, forceRefresh }) =>
  getCollection(
    domainKey && userId
      ? `domains/${domainKey}/domainReviews/${userId}/pageReviews`
      : null,
    {},
    { forceRefresh },
  )

export const getDomainPacket = async ({ domainKey, userId, forceRefresh }) => {
  const domainReview = await getDocument(
    domainKey && userId ? `domains/${domainKey}/domainReviews/${userId}` : null,
    { forceRefresh },
  )

  const pageReviews =
    domainReview &&
    // First load: domainReview has reviews. Refresh: just check (only when domain or page review saved, so checking not too frequent)
    ((domainReview.exists && domainReview.meta?.numPageReviews) || forceRefresh)
      ? // Note: could be hydrating separate ruleReviews subcollection as well (but have decided to keep embedded for now)
        keyBy(
          await getDomainPageReviews({ domainKey, userId, forceRefresh }),
          ({ id }) => id,
        )
      : {}

  // TODO: implement rule reviews
  const ruleReviews = []

  // console.log('getDomainPacket', domainKey, userId, {
  //   domainReview,
  //   domainKey,
  //   pageReviews,
  //   ruleReviews,
  //   NORMALIZED_domainPacket: buildDomainPacket({
  //     domainKey,
  //     domainReview,
  //     pageReviews,
  //     ruleReviews,
  //     userId,
  //   }),
  // })

  return buildDomainPacket({
    domainKey,
    userId,
    domainReview,
    pageReviews,
    ruleReviews,
  })
}

export const getTopHashtagsForUserId = async (userId) =>
  await getCollection(userId ? `users/${userId}/userHashtags` : null, {
    orderBy: [
      ['meta.numDomainReviews', 'desc'],
      ['meta.numPageReviews', 'desc'],
    ],
    limit: 20,
  })
