import React from 'react'

import Alert from 'cc/Alert'
import { inspectObject } from 'common/utils'
import { isObject } from 'lodash'

// Based on https://reactjs.org/docs/error-boundaries.html
class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null,
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.warn('getDerivedStateFromError', { error })
    return {
      hasError: true,
      error: error.message || 'Please refresh and try again.',
    }
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { hasError, error } = this.state

    if (hasError) {
      return (
        <Alert variant="warning">
          <Alert.Heading variant="warning">Something went wrong</Alert.Heading>
          {process.env.NODE_ENV === 'production'
            ? 'Please refresh and try again.'
            : error && (isObject(error) ? inspectObject(error) : error)}
        </Alert>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
