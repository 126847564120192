import { getCurrentUserJWT } from 'common/siteApi'
import toast from 'react-hot-toast'
import bext from 'utils/bext'

import { incrementIsExtInstalledCheck } from 'lib/recoil'

export const doExtLogIn = async ({ wasSignedOut } = {}) => {
  const { token, uid } = await getCurrentUserJWT()
  if (!token || !uid) return

  const resp = await bext.thisIsYou(token)

  if (wasSignedOut && resp?.handle) {
    toast.success(
      <div className="flex flex-wrap">
        <span className="font-bold mr-1">@{resp.handle}</span> logged into
        extension!
      </div>,
      { duration: 6000 },
    )
  }

  // TODO: set some recoil state here to indicate that the user has been logged into the extension
  if (resp?.handle) incrementIsExtInstalledCheck()
}
