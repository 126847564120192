import { getCollection, getDocument } from 'utils/swrFirestore/core'
import { normalizeReview } from 'common/utils'
import { compact } from 'lodash'

// ...props allows passing custom parseDates
export const makeCollectionTopGetter =
  (collection, orderKey, props = {}) =>
  () => {
    const { limit = 25, parseDates } = props
    return getCollection(
      collection,
      {
        orderBy: [orderKey, 'desc'],
        where: [orderKey, '>', 0], // meta counts may go to zero without deleting the underlying record - avoid showing those
        limit,
      },

      // Little heavy-handed, but need this for getMostReviewedDomains
      parseDates ? { parseDates } : {},
    )
  }

export const getReviewDocument = async (reviewKey) => {
  const review = await getDocument(reviewKey)

  // console.log('[getReviewDocument] review:', {
  //   review,
  //   normalized: normalizeReview(review),
  // })

  if (!review || !review.exists) return null
  return normalizeReview(review)
}

export const getReviewCollection = async (collectionKey, opts) => {
  const reviews = await getCollection(collectionKey, opts)

  // console.log('[getReviewCollection] reviews:', {
  //   reviews,
  //   normalized: compact(reviews).map(normalizeReview),
  // })

  return compact(reviews).map(normalizeReview)
}
