import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, CloseIcon } from 'cc/icons'
import NavLogo from 'cc/NavLogo'

import { NavLink } from './index'
import config from 'common/config'

const CollapsiblePrimaryNav = ({ links, className }) => {
  return (
    <Popover className={className}>
      <>
        <Popover.Button className="inline-flex items-center justify-center p-2 ml-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
          <span className="sr-only">Open main menu</span>
          <MenuIcon className="w-6 h-6" aria-hidden="true" />
        </Popover.Button>

        <Popover.Panel
          focus
          className="absolute top-0 right-0 p-2 transition origin-top-right md:hidden w-[330px] z-10"
        >
          <Transition
            enter="duration-150 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="overflow-hidden bg-gray-50 rounded-lg shadow-md ring-1 ring-black ring-opacity-5">
              <div className="flex items-center justify-between px-5 pt-4">
                <NavLogo className="w-20" />

                <div className="-mr-2">
                  <Popover.Button className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Close main menu</span>
                    <CloseIcon className="w-6 h-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                {links.map(({ name, href }) => (
                  <NavLink
                    key={name}
                    text={name}
                    href={href}
                    className="block px-3 py-2 text-base text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-200"
                  />
                ))}
              </div>
            </div>
          </Transition>
        </Popover.Panel>
      </>
    </Popover>
  )
}

export default CollapsiblePrimaryNav
