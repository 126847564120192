import { parseDomain, ParseResultType } from 'parse-domain'

const RECORDABLE_PROTOCOLS = ['http:', 'https:']

export const parseValidDomainKey = (rawDomain) => {
  const parts = extractParseResultFromHostname(rawDomain)
  return parts ? domainKeyFromParts(parts) : null
}

const extractParseResultFromHostname = (rawDomain) => {
  const parseResult = parseDomain(rawDomain)

  if (parseResult.type === ParseResultType.Listed) {
    // Important! Use icann to avoid weird issues like "github.io" technically being it's own
    // tld: https://github.com/peerigon/parse-domain#%EF%B8%8F-effective-tlds--tlds-acknowledged-by-icann
    return parseResult.icann
  }
  // console.log('URL parsed as non-listed - Skipping', { ...parseResult })
}

// Given already-parsed URL
// Returns undefined or { subDomains, domain, topLevelDomains }
const parsedDomainPartsFromURL = (url) => {
  // Abort early for e.g. chrome://extensions or file:// URLs
  if (!RECORDABLE_PROTOCOLS.includes(url.protocol)) {
    // process.env.NODE_ENV === 'development' &&
    //   console.log(`Bad Protocol (${url.protocol}) - Aborting Parsing: `, {
    //     ...url,
    //   })
    return undefined
  }

  return extractParseResultFromHostname(url.hostname)
}

// http://foo.bar.example.co.uk/some/path -> example.co.uk
export const domainKeyFromAddress = (address) => {
  if (!address) return null

  const url = new URL(address)
  const parts = parsedDomainPartsFromURL(url)
  return domainKeyFromParts(parts)
}

// TODO:MSIE - when we support IE, need to polyfill this (e.g. url-search-params-polyfill) https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
const parseParamsFromSearch = (raw) => Object.fromEntries(new URLSearchParams(raw))

const domainKeyFromParts = (parts) => parts && [parts.domain, ...parts.topLevelDomains].join('.')

// Return subdomain, domainKey, and path from given address string,
// using the github-io-as-tld-aware parse-domain to determine subdomain boundary.
export const urlDataFromAddress = (address) => {
  const url = new URL(address)
  const parts = parsedDomainPartsFromURL(url)

  if (parts) {
    return {
      subdomain: parts.subDomains.join('.'),
      domainKey: domainKeyFromParts(parts),
      path: url.pathname,
      params: url.search && url.search.length ? parseParamsFromSearch(url.search) : {},
    }
  }
}
