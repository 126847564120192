import renderFullyWrappedComponent from 'utils/renderFullyWrappedComponent'
import InjectFonts from 'utils/InjectFonts'
import DefaultLayout from 'components/layouts/DefaultLayout'
import 'styles/application.scss'

// TODO: link to Google Analytics https://nextjs.org/docs/advanced-features/measuring-performance
// Note: linked from firebase already via fuego
export function reportWebVitals(metric) {
  // TODO:
  // console.log('[WebMetric]', JSON.stringify(metric))
}

const outputWithFont = (Component, pageProps) => (
  <>
    <InjectFonts />
    <Component {...pageProps} />
  </>
)

export default function App({ Component, pageProps }) {
  const content = outputWithFont(Component, pageProps)

  if (Component.barePage) {
    // Too bad we can't get our own app, ideally we wouldn't pull in application.scss at all + could have separate tailwind files.
    // But separating the apps ended up causing too much trouble w/ shared logic
    return content
  }
  const Layout = Component.Layout ? Component.Layout : DefaultLayout

  // TODO: inject the fonts here, too...

  return renderFullyWrappedComponent(Component, pageProps, Layout)
}
