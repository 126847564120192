import { FooterLink, FOOTER_LINK_CLASS_NAMES } from 'components/Link'
import { cn } from 'common/utils'
import routes from 'common/routes'
import config from 'common/config'
import emails from 'common/config/emails'
import Obfuscate from 'react-obfuscate'
import Logo from 'cc/Logo'
import { FacebookIcon, InstagramIcon, TwitterIcon } from 'cc/icons'

const socials = [
  [TwitterIcon, config.socials.twitter.url],
  [InstagramIcon, config.socials.instagram.url],
  [FacebookIcon, config.socials.facebook.url],
]

const DefaultFooter = ({ className, domainLogos }) => {
  return (
    <div className={className}>
      <div className="px-3 pt-6 sm:px-6 sm:pt-6 md:px-24 md:pt-24 lg:px-8 lg:pt-8 xl:px-12 xl:pt-12">
        <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
          <div className="sm:col-span-2">
            <FooterLink
              href="/"
              aria-label="Go home"
              title={config.appName}
              className="group inline-flex items-center"
            >
              <Logo secondaryClassName="fill-gray-500 group-hover:fill-purple-500" />
              <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                {config.appName}
              </span>
            </FooterLink>
            <div className="mt-6 lg:max-w-sm">
              <p className="text-sm text-gray-800">
                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                accusantium doloremque laudantium, totam rem aperiam.
              </p>
              <p className="mt-4 text-sm text-gray-800">
                Eaque ipsa quae ab illo inventore veritatis et quasi architecto
                beatae vitae dicta sunt explicabo.
              </p>
            </div>
          </div>
          <div className="space-y-2 text-sm">
            <p className="text-base font-bold tracking-wide text-gray-900">
              Contacts
            </p>
            {false && (
              <div className="flex">
                <p className="mr-1 text-gray-800">Phone:</p>
                <FooterLink
                  href="tel:850-123-5021"
                  aria-label="Our phone"
                  title="Our phone"
                >
                  850-123-5021
                </FooterLink>
              </div>
            )}
            <div className="flex">
              <p className="mr-1 text-gray-800">Email:</p>

              <Obfuscate
                email={emails.support}
                className={FOOTER_LINK_CLASS_NAMES}
              />
            </div>
            <div className="flex">
              <p className="mr-1 text-gray-800">Address:</p>
              <FooterLink
                href="https://www.google.com/maps"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Our address"
                title="Our address"
              >
                312 Lovely Street, NY
              </FooterLink>
            </div>
          </div>
          <div>
            <span className="text-base font-bold tracking-wide text-gray-900">
              Social
            </span>
            <div className="flex items-center mt-2 space-x-3">
              {socials.map(([Icon, href]) => (
                <FooterLink
                  href={href}
                  target="_blank"
                  className="hover:scale-125"
                  key={href}
                >
                  <Icon />
                </FooterLink>
              ))}
            </div>
            <p className="mt-4 text-sm text-gray-500">
              Bacon ipsum dolor amet short ribs pig sausage prosciutto chicken
              spare ribs salami.
            </p>
          </div>
        </div>
        <div className="flex flex-col-reverse justify-between py-5 border-t border-gray-100/25 lg:flex-row">
          <div className="flex flex-col">
            <p className="text-sm text-gray-700">
              © Copyright {new Date().getFullYear()} {config.companyName} All
              rights reserved.
            </p>
            {domainLogos ? (
              <p className="text-sm text-gray-600">
                Domain logos thanks to{' '}
                <FooterLink
                  href="https://clearbit.com/"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  clearbit
                </FooterLink>
                .
              </p>
            ) : null}
          </div>

          <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
            <li>
              <FooterLink href={routes.faqPath} className="text-sm">
                FAQ
              </FooterLink>
            </li>
            <li>
              <FooterLink href={routes.privacyPolicyPath} className="text-sm">
                Privacy Policy
              </FooterLink>
            </li>
            <li>
              <FooterLink href={routes.termsOfServicePath} className="text-sm">
                Terms &amp; Conditions
              </FooterLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default DefaultFooter
