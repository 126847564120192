import { Vollkorn, Montserrat } from '@next/font/google'

const vollkorn = Vollkorn()
const montserrat = Montserrat()

const InjectFonts = () => (
  <style jsx global>{`
    :root {
      --font-vollkorn: ${vollkorn.style.fontFamily};
      --font-montserrat: ${montserrat.style.fontFamily};
    }
  `}</style>
)

export default InjectFonts
