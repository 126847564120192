export const devRoot = 'http://localhost:3000'
export const prodRoot = 'https://voyd.center'

// Used to make a dev mode build use voyd.center as the base for API calls (i.e. if manually exporting to test in Leigh's browser)
const BUILDING_FOR_PRODUCTION = false

// TODO: load from here to extension's config/manifest.index.js... FOR NOW KEEP MANUALLY SYNCED
const config = {
  appName: 'voyd',
  companyName: 'voyd', // TODO
  siteRootUrl: BUILDING_FOR_PRODUCTION || process.env.NODE_ENV === 'production' ? prodRoot : devRoot,
  screenshotRootUrl:
    BUILDING_FOR_PRODUCTION || process.env.NODE_ENV === 'production'
      ? 'https://screenshots.voyd.center'
      : 'http://localhost:3001',
  fbAppId: null, // for FacebookMessengerShareButton
  companyAddress: '7531 408th Ave SE, Snoqualmie WA 98065',
  urls: {
    extensionDownload: {
      chrome: 'https://chrome.google.com/webstore/detail/voyd/iogogpoolhchcgelchpbekgkbphpenad',
      // firefox: 'https://addons.mozilla.org/en-US/firefox/', // TODO
      // opera: 'https://addons.opera.com/en/extensions/details/voyd/', // TODO
      // safari: 'https://www.apple.com/safari/', // TODO
      // edge: 'https://microsoftedge.microsoft.com/addons/Microsoft-Edge-Extensions-Home', // TODO
    },
  },

  socials: {
    facebook: {
      appId: '', // TODO
      url: 'https://www.facebook.com/Voyd.Center',
      handle: 'Voyd.Center',
    },
    twitter: {
      url: 'https://twitter.com/CenterOfTheVOYD',
      handle: 'CenterOfTheVOYD',
    },
    instagram: {
      url: 'https://www.instagram.com/voyd.center/',
      handle: 'voyd.center',
    },
  },
}

export default config
