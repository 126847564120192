import { useHasMounted } from 'common/hooks'
import { useCallback, useMemo } from 'react'

// https://codepedia.info/detect-browser-in-javascript
function fnBrowserDetect() {
  if (!global.navigator) return

  let userAgent = navigator.userAgent
  let browserName = null

  if (userAgent.match(/opr\//i)) {
    browserName = 'opera'
  } else if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = 'chrome'
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = 'firefox'
  } else if (userAgent.match(/safari/i)) {
    browserName = 'safari'
  } else if (userAgent.match(/edg/i)) {
    browserName = 'edge'
  }

  return browserName
}

export const useCurrentBrowser = () => {
  return useMemo(fnBrowserDetect, [])
}
