import { useState, useEffect, useCallback, useMemo } from 'react'

import { isBoolean } from 'lodash'

export const useToggle = (initialValue) => {
  const [toggleValue, setToggleValue] = useState(!!initialValue)
  const toggler = useCallback(
    (explicitValue) => {
      // if explicitValue is a click event, swallow it (<a /> onClick handling)
      explicitValue?.preventDefault && explicitValue.preventDefault()

      setToggleValue(isBoolean(explicitValue) ? explicitValue : !toggleValue)
    },
    [toggleValue],
  )

  return [toggleValue, toggler]
}

// useCallback, but if applied directly to a.onClick it swallows the click event
export const useLinkCallback = (fn, deps) =>
  /* eslint-disable */
  useCallback((event) => {
    event?.preventDefault && event?.preventDefault()
    fn()
  }, deps)
/* eslint-enable */

export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  return hasMounted
}
