import { compact } from 'lodash'

// !! CAUTION !! This code is **manually** copied to the firebase repo (used to determine what page to scrape when a new pageKey record is created)
// Any changes **must** be manually duplicated there, until we finalize which other bits of logic are shared and get them all extracted into a bit component.
export const addressFromPageKey = ({ pageKey, domainKey, viaHttp, viaWww, withoutProtocol }) => {
  if (!pageKey?.length) return null
  const { subdomain, path, search } = partsFromPageKey(pageKey)

  return [
    withoutProtocol ? '' : viaHttp ? 'http://' : 'https://',
    subdomain?.length ? `${subdomain}.` : viaWww ? 'www.' : '',
    domainKey,
    path.replace(/\/{2,}/, '/'),
    search,
  ].join('')
}

export const EncodePathSlashWith = ':'
export const EncodeParamsSlashWith = '#'
export const SubdomainPathSeparator = ';'

const pathDecoder = new RegExp(EncodePathSlashWith, 'g')
const decodePath = (path) => path.replace(pathDecoder, '/')

const paramsDecoder = new RegExp(EncodeParamsSlashWith, 'g')
const decodeParams = (params) => params.replace(paramsDecoder, '/')

export const partsFromPageKey = (pageKey) => {
  if (!pageKey?.length) return {} // todo: parse for valid pageKey -- caller knows invalid returns empty path

  const paramIdx = pageKey.indexOf('?')
  const search = paramIdx === -1 ? null : decodeParams(pageKey.slice(paramIdx))

  const main = paramIdx === -1 ? pageKey : pageKey.slice(0, paramIdx)
  const subIdx = main.indexOf(SubdomainPathSeparator)
  const _sub = subIdx === -1 ? null : main.slice(0, subIdx)
  const _path = subIdx === -1 ? main : main.slice(subIdx + 1)
  const decodedPath = decodePath(_path)

  return {
    search,
    subdomain: _sub?.length ? _sub : null,
    path: [decodedPath[0] === '/' ? '' : '/', decodedPath].join(''),
  }
}

// ===== END MANUALLY-MAINTAINED CODE BLOCK ========

export const pageKeyFromComponents = ({ path, subdomain, search, encodeParams }) => {
  const pathKey = path.replace('/', '').replace(/\//g, EncodePathSlashWith)

  const paramsKey = search?.length && encodeParams ? search.replace(/\//g, EncodeParamsSlashWith) : null

  const pageKey = compact([
    compact([subdomain, pathKey.length === 0 ? ':' : pathKey]).join(SubdomainPathSeparator),
    paramsKey,
  ]).join('')

  return pageKey
}

export const pageKeyAndPartsFromAddress = ({
  address,
  domainKey,
  encodeParams, // Not yet used, but allows us to build a pageKey that takes encodes params as well (by default, they're stripped out)
  url, // Optional, for caching if we've already parsed it
}) => {
  try {
    url = url || new URL(address)
  } catch (e) {
    return {}
  }

  // Note by default we DO include subdomain, but do NOT include params, in the generated pageKey
  // (we can support either way, but for simplicity we default to the above and then have the user
  // use their manage - reviews page to add any advanced overrides)
  const { protocol, host, search, pathname: path } = url
  const hostKey = host.replace(domainKey, '')
  const subdomain = !hostKey.length
    ? null
    : hostKey[hostKey.length - 1] === '.'
    ? hostKey.slice(0, hostKey.length - 1)
    : hostKey // Strip off the trailing

  const pageKey = pageKeyFromComponents({
    path,
    subdomain,
    search,
    encodeParams,
  })

  return {
    pageKey,
    pageAddressParts: {
      protocol,
      address,
      subdomain,
      path,
      params: search.length ? search : null, // TODO: convert params?
    },
  }
}
