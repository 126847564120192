import { askExtensionTo } from './writing'
export { listenToExtension } from 'utils/bext/listening'

const actions = {
  sayHi: (n) =>
    askExtensionTo('sayHi', n).catch((e) => {
      console.log('[Voyd][Ext] Error saying hello:', e)
    }),

  thisIsYou: (token) =>
    askExtensionTo('thisIsYou', token).catch((e) => {
      console.log('[Voyd][Ext] Error authorizing extension:', e)
    }),

  isExtensionInstalled: async () => {
    try {
      return await askExtensionTo('isExtensionInstalled')
    } catch (e) {
      // Probably timed out
      return false
    }
  },

  // Fire-and-forget
  refreshFollowedUsers: () =>
    askExtensionTo('refreshFollowedUsers')
      .then((resp) => {
        // TODO: remove once it's working again...
        console.log('[Voyd][Ext] Responded with:', resp)
      })
      .catch((e) => {
        console.log('[Voyd][Ext] Error refreshing followed users:', e)
      }),
}

export default actions
