import { useCallback, useEffect, useState } from 'react'
import { uniqBy } from 'lodash'

// To handle creatables, we need to be able to dynamically append new options to those provided
// NOTE: initialOptions name is a bit misleading -- currently we always append to the existing array if the options key changes
// (e.g. initially loading page w / no user, then loading their hashtags later) -- resetting to a different options set will require code changes.
const useLocalOptions = ({ initialOptions }) => {
  const [localOptions, setLocalOptions] = useState(initialOptions)

  const addLocalOptions = useCallback(
    (newOptions) => {
      setLocalOptions(uniqBy([...localOptions, ...newOptions], (opt) => opt.value))
    },
    [localOptions],
  )

  // We want any new options to be added, not replace, the existing ones
  useEffect(() => {
    addLocalOptions(initialOptions)
  }, [initialOptions]) // Note: do NOT add addLocalOptions to dependency array (circular)

  return { localOptions, addLocalOptions }
}

export default useLocalOptions
