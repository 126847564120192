import config from 'common/config'
import { FormError } from 'common/errors'

import { getBearerToken } from 'universal/auth'

const siteRoot = config.siteRootUrl

class APILocalClientError extends Error {
  constructor(message) {
    super(message)
    this.name = 'LocalClientAPIError'
  }
}

// Need a better way to check if server intended to return JSON...
const parseResponseJson = async (response) => {
  try {
    return await response.json()
  } catch (e) {
    console.warn('Unable to parse JSON from upstream response - did you forget to set JSON?', {
      response,
    })
    return {}
  }
}

// B/c "The Promise returned from fetch() won't reject on HTTP error status even if the response is an HTTP 404 or 500"
async function errorFromResponse(response) {
  if (response.status >= 200 && response.status < 300) return

  const errorJson = await parseResponseJson(response)

  const errorMessage = errorJson?.error ? errorJson?.error : response.statusText || 'server error'

  const error = errorJson?.formError
    ? new FormError(errorJson.formError.fieldErrors, errorMessage)
    : new APILocalClientError(errorMessage)

  error.response = response

  return error
}

// https://dmitripavlutin.com/timeout-fetch-request/
async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 10000 } = options

  const abortController = new AbortController()
  const id = setTimeout(() => abortController.abort(), timeout)
  const response = await fetch(resource, {
    ...options,
    signal: abortController.signal,
  })
  clearTimeout(id)
  return response
}

// https://gist.github.com/briancavalier/842626?permalink_comment_id=3401417#gistcomment-3401417 w/ fix from following comment
const wait = (interval) => new Promise((resolve) => setTimeout(resolve, interval))
async function retryPromise(fn, retriesLeft = 2, interval = 1) {
  try {
    const result = await fn()
    console.log(`[retryPromise] calling fn`, { fn, result })
    return result
  } catch (error) {
    console.log('siteClient caught an error in fetchWithTimeout: ', error, {
      retriesLeft,
    })
    if (retriesLeft === 0) throw new Error('[Retried] ' + error)

    await wait(interval)

    return await retryPromise(fn, --retriesLeft, interval)
  }
}

// TODO: can remove this logging once we track down whether intermittent super-long "save review" requests are being caused by
// the auth call or the retried fetchWithTimeout
const client = async (path, method = 'GET', data = null) => {
  console.log('[siteClient] 1. getting bearer token (with retries if needed)', {
    path,
    method,
    data,
  })

  // TRYING to work around the sometimes-have-to-resubmit-because-you're-logged-out bug
  const token = await retryPromise(async () => {
    const token = await getBearerToken()
    if (!token) throw new Error('No token')
    return token
  })

  const headers = {
    Authorization: token,
  }
  if (data) headers['Content-Type'] = 'application/json'
  const opts = { method, headers }
  if (data) opts.body = JSON.stringify(data)

  console.log('[siteClient] 2. firing retryPromise', {
    path,
    method,
    data,
    token,
  })

  // TODO: REMOVE THIS IN PRODUCTION (or... at least only retry once?) once we've tracked down this current intermittent super-long saving review bug
  const resp = await retryPromise(() => fetchWithTimeout(`${siteRoot}/api/${path}`, opts))
  // const resp = fetchWithTimeout(`${siteRoot}/api/${path}`, opts)

  console.log('[siteClient] 3. got response from retryPromise', resp, {
    path,
    method,
    data,
    status: resp.status,
  })

  const error = await errorFromResponse(resp)
  if (error) return Promise.reject(error?.message || 'Server error')

  const returnedData = await parseResponseJson(resp)
  return returnedData
}

// Works: <Button variant="success" onClick={() => client('hello')}>
export default client
