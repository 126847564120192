// Default and per-success/failure/etc styles: https://react-hot-toast.com/docs/toaster
const appToastOptions = {
  // className: '',
  position: 'bottom-right',
  style:
    process.env.APP_CONTEXT === 'extension'
      ? {
          maxWidth: '220px',
        }
      : {},
}

export default appToastOptions
