import SelectField from './SelectField'
import { MIN_HASHTAG_LENGTH, stringToHashtag } from 'common/utils'
import { forwardRef, useCallback } from 'react'

export const makeOptionFromHashtag = (hashtag, extra) =>
  hashtag?.value && hashtag.label
    ? Object.assign({}, extra || {}, hashtag)
    : Object.assign({}, extra || {}, { label: `#${hashtag}`, value: hashtag })

const getNewHashtagOptionData = (input) => {
  const hashtag = stringToHashtag(input)
  return makeOptionFromHashtag(hashtag, { __isNew__: true })
}

const formatHashtagCreateLabel = (input) => (
  <p className="mb-0">
    Add: <em>#{stringToHashtag(input)}</em>
  </p>
)

const isValidNewHashtag = (input, value, options) => {
  if (input.length < MIN_HASHTAG_LENGTH) return false
  const hashtag = stringToHashtag(input)

  if (hashtag.length < MIN_HASHTAG_LENGTH) return false // Don't allow e.g. "hashtag" of space characters

  // Don't allow creating a hashtag that is already in the options list
  if (options.find(({ value }) => value === hashtag)) return false

  return true
}

const noOptionsMessage = ({ inputValue }) => {
  if (inputValue?.length <= MIN_HASHTAG_LENGTH)
    return <p className="mb-0">Hashtags must be at least {MIN_HASHTAG_LENGTH} characters</p>
  const hashtag = stringToHashtag(inputValue)

  if (hashtag.length <= MIN_HASHTAG_LENGTH)
    return (
      <p className="mb-0">
        Hashtags must be at least {MIN_HASHTAG_LENGTH} characters (be sure you entered a valid hashtag)
      </p>
    )

  // Default fallback - theoretically shouldn't ever get here unless it doesn't exist
  return (
    <p className="mb-0">
      Cannot create new hashtag from: <em>{inputValue}</em>
      <br /> <small>(does it already exist?)</small>
    </p>
  )
}

const HashtagSelectField = forwardRef(({ options, ...props }, ref) => (
  <SelectField
    placeholder="Hashtags"
    options={options || []}
    {...props}
    formatCreateLabel={formatHashtagCreateLabel}
    isValidNewOption={isValidNewHashtag}
    noOptionsMessage={noOptionsMessage}
    createOptionFn={getNewHashtagOptionData}
    creatable
    isMulti
    ref={ref}
  />
))

export default HashtagSelectField
