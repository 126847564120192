import { urlDataFromAddress, ruleReviewMatches } from 'common/logic'
import { normalizeReview } from 'common/utils'

import { pageKeyAndPartsFromAddress } from 'common/logic'

// *** SCORING ***

// TODO: combine these two -- basically just whether we limit ourselves to domain|page or return any

// TODO: remove need to return reviewType (now that we're separating them out in the UI)
// Returns [vote, reviewType, review]
const scoreDomainPacketWithoutContext = ({ domainPacket, targetForRuleReview, targetPageKey }) => {
  if (!domainPacket) return
  const { domainReview, pageReviews, ruleReviews } = domainPacket

  // First: check page reviews for any exact matches
  const page = pageReviews[targetPageKey]
  if (page)
    return {
      vote: page.vote,
      reviewType: 'pageReview',
      review: normalizeReview(page),
    }

  // Second: check ruleReviews for the first match, if any (assumes sorted by priority)
  if (ruleReviews.length) {
    const ruleReview = domainPacket.ruleReviews.find((ruleReview) => ruleReviewMatches(ruleReview, targetForRuleReview))
    if (ruleReview)
      return {
        vote: ruleReview.vote,
        reviewType: 'ruleReview',
        review: normalizeReview(ruleReview),
      }
  }

  // Finally, default to the domain's review
  if (domainReview?.vote || domainReview?.vote === 0)
    return {
      vote: domainReview.vote,
      reviewType: 'domainReview',
      review: domainReview,
    }
}

// TODO: remove need to return reviewType (now that we're separating them out in the UI)
// Returns [vote, reviewType, review]
const scoreDomainPacketForContext = ({ domainPacket, targetForRuleReview, targetPageKey, readingContext }) => {
  if (!domainPacket) return {}
  const { domainReview, pageReviews = {}, ruleReviews = [] } = domainPacket

  if (readingContext === 'domain')
    return domainReview?.vote || domainReview?.vote === 0
      ? {
          vote: domainReview.vote,
          reviewType: 'domainReview',
          review: normalizeReview(domainReview),
        }
      : {}

  // First: check page reviews for any exact matches
  const page = pageReviews[targetPageKey]
  if (page)
    return {
      vote: page.vote,
      reviewType: 'pageReview',
      review: normalizeReview(page),
    }

  // Second: check ruleReviews for the first match, if any (assumes sorted by priority)
  if (ruleReviews?.length) {
    const ruleReview = domainPacket.ruleReviews.find((ruleReview) => ruleReviewMatches(ruleReview, targetForRuleReview))
    if (ruleReview)
      return {
        vote: ruleReview.vote,
        reviewType: 'ruleReview',
        review: normalizeReview(ruleReview),
      }
  }
}

export const forDomainPacketTest = {
  scoreDomainPacketWithoutContext,
}

const compare = (a, b) => (a < b ? -1 : a > b ? 1 : 0)
const reviewDateComparison = (reviewA, reviewB) =>
  compare(
    Date.parse(reviewA.meta?.updatedAt || reviewA.meta?.createdAt),
    Date.parse(reviewB.meta?.updatedAt || reviewB.meta?.createdAt),
  )

const sortScoredReviews = (scores) =>
  (scores || []).sort(({ review: reviewA }, { review: reviewB }) => -1 * reviewDateComparison(reviewA, reviewB))

const addScoreStats = (score) => {
  const numNeutral = score.neutralReviews.length
  const neutralWon = numNeutral > score.positiveReviews.length && numNeutral > score.negativeReviews.length

  const delta = neutralWon ? 0 : score.positiveReviews.length - score.negativeReviews.length
  const overallVote = neutralWon ? 0 : delta > 0 ? 1 : delta === 0 ? 0 : -1

  return {
    delta,
    overallVote,
    ...score,
  }
}

export const targetsForAddress = (address) => {
  // ruleReviews will be checked against the parsed urlData from the address (checked against our custom domainKey/subdomain parsing, *not* just new URL)
  const targetForRuleReview = address ? urlDataFromAddress(address) : undefined

  // pageReviews will be checked against the pageKey derived from the given address
  const { pageKey: targetPageKey } = pageKeyAndPartsFromAddress({
    address,
    domainKey: targetForRuleReview?.domainKey,
  })

  return { targetForRuleReview, targetPageKey }
}

// Given:
//  - Address (unparsed URL)
//  - domainPackets (loaded domainPackets for underlying domainKey -- already normalized pls)
//  - candidateUsers (array of users - if provided MUST contain all review authors (will be injected into review.user so downstream doesn't have to figure it out))
//  - readingContext -- if provided, only evaluates the [domain|page] rules for score, otherwise evaluates all reviews
export const scoreAddressAgainstDomainPackets = ({ address, domainPackets, candidateUsers, readingContext = null }) => {
  const { targetForRuleReview, targetPageKey } = targetsForAddress(address)

  // Bail early if we're missing anything required
  if (!targetPageKey || !targetForRuleReview || !candidateUsers || !domainPackets || domainPackets.length === 0)
    return addScoreStats({
      positiveReviews: [],
      negativeReviews: [],
      neutralReviews: [],
    })

  const {
    1: positiveReviews,
    '-1': negativeReviews,
    0: neutralReviews,
  } = domainPackets.reduce((aggregate, domainPacket) => {
    const { vote, reviewType, review } =
      (readingContext
        ? scoreDomainPacketForContext({
            domainPacket,
            targetForRuleReview,
            targetPageKey,
            readingContext,
          })
        : scoreDomainPacketWithoutContext({
            domainPacket,
            targetForRuleReview,
            targetPageKey,
          })) || {}

    // e.g. we add a placeholder domain review if there isn't one already when page review added -- exclude that from UI
    if (review?.meta?.placeholder || typeof vote === 'undefined') return aggregate

    const user =
      candidateUsers.find(
        // Looks weird but correct, packet was pulled from /domains/domainKey/domainReviews/userId, so id should === userId
        (user) => user.id === domainPacket.id,
      ) || candidateUsers.find((user) => user.id === domainPacket.meta.userId)
    // TODO: confirm where that old logic is used in web... above line is for new v3 ext

    if (user) {
      aggregate[`${vote}`].push({
        user,
        reviewType,
        review,
        domainPacket,
      })
    } else {
      console.log('Not returning review with no matching user (confirm candidate users had IDs set):', {
        reviewType,
        review,
        domainPacket,
        candidateUsers,
      })
    }

    return aggregate
  }, new Object({ 1: [], 0: [], '-1': [] }))

  return addScoreStats({
    targetPageKey,
    positiveReviews: sortScoredReviews(positiveReviews),
    negativeReviews: sortScoredReviews(negativeReviews),
    neutralReviews: sortScoredReviews(neutralReviews),
  })
}
