import { cn } from 'common/utils'

// TODO: Only used in ReviewStaff -- once fix that, can remove this
export const OldLogo = ({ className, alt, upClassName = 'text-gray-800', downClassName = 'text-gray-300' }) => {
  return (
    // TODO: fix this logo, it's a rough approximation but still waiting for official version back
    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" className={className} alt={alt}>
      <g fill="none" strokeMiterlimit="10" strokeWidth="10" transform="translate(60, 50)" stroke="currentColor">
        <path id="down" d="m 0 0 h-55.5l80.017 122.49 79.983-122.49h-52.41" className={downClassName} />
        <path
          id="up"
          d="m 0 0 h-55.5l80.017-122.49 79.983 122.49h-52.41"
          transform="translate(0, 110)"
          className={upClassName}
        />
      </g>
    </svg>
  )
}

export const Logo = ({ className = 'w-8', primaryClassName, secondaryClassName = 'fill-gray-100' }) => {
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 100.96">
        <g id="VOYD">
          <polygon
            className={secondaryClassName}
            points="128 97.99 83.79 97.99 83.79 90.82 114.75 90.82 64.01 13.12 13.25 90.82 46.43 90.82 46.43 97.99 0 97.99 64.01 0 128 97.99"
          />
          <polygon
            className={primaryClassName}
            points="64.01 100.96 0 2.97 46.43 2.97 46.43 10.14 13.25 10.14 64.01 87.85 114.75 10.14 83.79 10.14 83.79 2.97 128 2.97 64.01 100.96"
          />
        </g>
      </svg>
    </div>
  )
}

export const LogoWithTitle = ({ className }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 23.63" className={className}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="VOYD">
          <g id="Main">
            <g id="Space">
              <polygon
                style={{ fill: '#cecece' }}
                points="29.96 22.93 19.61 22.93 19.61 21.25 26.86 21.25 14.98 3.07 3.1 21.25 10.87 21.25 10.87 22.93 0 22.93 14.98 0 29.96 22.93"
              />
              <polygon points="14.98 23.63 0 0.69 10.87 0.69 10.87 2.37 3.1 2.37 14.98 20.56 26.86 2.37 19.61 2.37 19.61 0.69 29.96 0.69 14.98 23.63" />
            </g>
            <path d="M43.78,5.89l-5.2,11.85H36.9L31.69,5.89h1.83l4.26,9.73,4.3-9.73Z" />
            <path d="M46.94,17.09a5.85,5.85,0,0,1-2.26-2.18,6,6,0,0,1-.82-3.1,6,6,0,0,1,.82-3.09,5.85,5.85,0,0,1,2.26-2.18,7,7,0,0,1,6.45,0,5.8,5.8,0,0,1,2.25,2.17,6,6,0,0,1,.82,3.1,6,6,0,0,1-.82,3.11,5.8,5.8,0,0,1-2.25,2.17,7.06,7.06,0,0,1-6.45,0Zm5.58-1.31a4.38,4.38,0,0,0,1.64-1.64,4.57,4.57,0,0,0,.6-2.33,4.56,4.56,0,0,0-.6-2.32,4.38,4.38,0,0,0-1.64-1.64,4.7,4.7,0,0,0-2.34-.59,4.84,4.84,0,0,0-2.37.59,4.33,4.33,0,0,0-1.65,1.64,4.47,4.47,0,0,0-.61,2.32,4.48,4.48,0,0,0,.61,2.33,4.33,4.33,0,0,0,1.65,1.64,4.84,4.84,0,0,0,2.37.59A4.7,4.7,0,0,0,52.52,15.78Z" />
            <path d="M62.73,13.64v4.1H61.06V13.61L56.35,5.89h1.81L62,12.14l3.8-6.25h1.68Z" />
            <path d="M68.61,5.89h5a7.33,7.33,0,0,1,3.34.74,5.53,5.53,0,0,1,2.26,2.1,5.92,5.92,0,0,1,.8,3.08,5.93,5.93,0,0,1-.8,3.09A5.44,5.44,0,0,1,76.94,17a7.21,7.21,0,0,1-3.34.75h-5ZM73.5,16.27A5.51,5.51,0,0,0,76,15.71a4.05,4.05,0,0,0,1.68-1.57,4.47,4.47,0,0,0,.6-2.33,4.43,4.43,0,0,0-.6-2.32A4,4,0,0,0,76,7.92a5.51,5.51,0,0,0-2.53-.56H70.3v8.91Z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export const ArrowUp = ({ className }) => {
  return (
    <Logo
      className={cn(className, 'rotate-180')}
      primaryClassName="fill-green-600"
      secondaryClassName="fill-transparent"
    />
  )
}

export const ArrowDown = ({ className }) => {
  return <Logo className={cn(className)} primaryClassName="fill-red-600" secondaryClassName="fill-transparent" />
}

export const ArrowMid = ({ className }) => {
  // return (
  //   <Logo
  //     className={cn(className, '-rotate-90')}
  //     primaryClassName="fill-gray-400"
  //     secondaryClassName="fill-transparent"
  //   />
  // )
  // return (
  //   <svg
  //     xmlns="http://www.w3.org/2000/svg"
  //     zoomAndPan="magnify"
  //     viewBox="0 0 810 809.999993"
  //     preserveAspectRatio="xMidYMid meet"
  //     version="1.0"
  //     className={className}
  //   >
  //     <defs>
  //       <clipPath id="id1">
  //         <path
  //           d="M 0 0.238281 L 810 0.238281 L 810 776 L 0 776 Z M 0 0.238281"
  //           clipRule="nonzero"
  //         />
  //       </clipPath>
  //       <clipPath id="id2">
  //         <path
  //           d="M 0 23 L 810 23 L 810 799.46875 L 0 799.46875 Z M 0 23 "
  //           clipRule="nonzero"
  //         />
  //       </clipPath>
  //       <clipPath id="id3">
  //         <path
  //           d="M 0 0 L 354 0 L 354 482 L 0 482 Z M 0 0 "
  //           clipRule="nonzero"
  //         />
  //       </clipPath>
  //       <clipPath id="id4">
  //         <path
  //           d="M -5.8125 -160.867188 L 353.789062 75.945312 L 86.539062 481.765625 L -273.0625 244.957031 Z M -5.8125 -160.867188 "
  //           clipRule="nonzero"
  //         />
  //       </clipPath>
  //       <clipPath id="id5">
  //         <path
  //           d="M 457 0 L 810 0 L 810 493 L 457 493 Z M 457 0 "
  //           clipRule="nonzero"
  //         />
  //       </clipPath>
  //       <clipPath id="id6">
  //         <path
  //           d="M 457.554688 84.757812 L 818.730469 -149.640625 L 1083.257812 257.960938 L 722.082031 492.359375 Z M 457.554688 84.757812 "
  //           clipRule="nonzero"
  //         />
  //       </clipPath>
  //       <clipPath id="id7">
  //         <path
  //           d="M 0 319 L 355 319 L 355 810 L 0 810 Z M 0 319 "
  //           clipRule="nonzero"
  //         />
  //       </clipPath>
  //       <clipPath id="id8">
  //         <path
  //           d="M -271.4375 555.085938 L 88.828125 319.285156 L 354.933594 725.859375 L -5.328125 961.65625 Z M -271.4375 555.085938 "
  //           clipRule="nonzero"
  //         />
  //       </clipPath>
  //       <clipPath id="id9">
  //         <path
  //           d="M 444 328 L 810 328 L 810 810 L 444 810 Z M 444 328 "
  //           clipRule="nonzero"
  //         />
  //       </clipPath>
  //       <clipPath id="id10">
  //         <path
  //           d="M 711.949219 328.675781 L 1071.511719 565.539062 L 804.203125 971.320312 L 444.640625 734.460938 Z M 711.949219 328.675781 "
  //           clipRule="nonzero"
  //         />
  //       </clipPath>
  //     </defs>
  //     <g clipPath="url(#id1)">
  //       <path
  //         fill="#cecece"
  //         d="M 909.175781 775.800781 L 559.257812 775.800781 L 559.257812 719.0625 L 804.304688 719.0625 L 402.703125 104.222656 L 0.941406 719.0625 L 263.558594 719.0625 L 263.558594 775.800781 L -103.929688 775.800781 L 402.703125 0.402344 Z M 909.175781 775.800781 "
  //         fillOpacity="1"
  //         fillRule="nonzero"
  //       />
  //     </g>
  //     <g clipPath="url(#id2)">
  //       <path
  //         fill="#cecece"
  //         d="M 402.703125 799.300781 L -103.929688 23.90625 L 263.558594 23.90625 L 263.558594 80.640625 L 0.941406 80.640625 L 402.703125 695.5625 L 804.304688 80.640625 L 559.257812 80.640625 L 559.257812 23.90625 L 909.175781 23.90625 Z M 402.703125 799.300781 "
  //         fillOpacity="1"
  //         fillRule="nonzero"
  //       />
  //     </g>
  //     <g clipPath="url(#id3)">
  //       <g clipPath="url(#id4)">
  //         <path
  //           fill="#ffffff"
  //           d="M -5.8125 -160.863281 L 353.785156 75.949219 L 86.542969 481.761719 L -273.058594 244.953125 L -5.8125 -160.863281 "
  //           fillOpacity="1"
  //           fillRule="nonzero"
  //         />
  //       </g>
  //     </g>
  //     <g clipPath="url(#id5)">
  //       <g clipPath="url(#id6)">
  //         <path
  //           fill="#ffffff"
  //           d="M 457.558594 84.761719 L 818.734375 -149.636719 L 1083.253906 257.957031 L 722.078125 492.355469 L 457.558594 84.761719 "
  //           fillOpacity="1"
  //           fillRule="nonzero"
  //         />
  //       </g>
  //     </g>
  //     <g clipPath="url(#id7)">
  //       <g clipPath="url(#id8)">
  //         <path
  //           fill="#ffffff"
  //           d="M -271.433594 555.089844 L 88.828125 319.292969 L 354.933594 725.855469 L -5.332031 961.652344 L -271.433594 555.089844 "
  //           fillOpacity="1"
  //           fillRule="nonzero"
  //         />
  //       </g>
  //     </g>
  //     <g clipPath="url(#id9)">
  //       <g clipPath="url(#id10)">
  //         <path
  //           fill="#ffffff"
  //           d="M 711.945312 328.679688 L 1071.511719 565.542969 L 804.207031 971.316406 L 444.640625 734.453125 L 711.945312 328.679688 "
  //           fillOpacity="1"
  //           fillRule="nonzero"
  //         />
  //       </g>
  //     </g>
  //   </svg>
  // )
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className={cn(className, 'stroke-gray-400')}>
      <polygon fill="none" strokeWidth="10" points="30,100, 100,20 170,100 100,180" />
    </svg>
  )
}

export default Logo
