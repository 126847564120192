import fuego from 'utils/swrFirestore/fuego'
import { getUserById } from 'utils/hooks'

// Just a no-op for web context (for now at least)
export const syncedWithExtStorageEffect = (_key) => () => null
export const useInitialValueFromStorage = (key, defaultValue) =>
  defaultValue || null
export const updateBrowserIconOnChangeEffect = () => null

export const loadAuthAtomDefault = async ({ get }) =>
  typeof window === 'undefined'
    ? false
    : await new Promise((resolve, reject) => {
        fuego.auth().onAuthStateChanged(async (auth) => {
          if (auth?.uid) {
            const user = await getUserById(auth.uid)
            resolve(user)
          } else {
            resolve(false)
          }
        })
      })

export const activeUidsAtomDefaultGetter =
  (storageKey, followedUsersAtom) =>
  ({ get }) => {
    return (get(followedUsersAtom) || []).map((u) => u.id) || []
  }
