import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

export { useRecoilState, useRecoilValue, useSetRecoilState }
import { setRecoil } from 'recoil-nexus'

// Used to distribute state check so the triggered-from-page-wrapper auto login can be used in a specific page
export const isExtInstalledIdxCounterAtom = atom({
  key: 'isExtInstalledIdxCounter',
  default: 0,
})

export const incrementIsExtInstalledCheck = () =>
  setRecoil(isExtInstalledIdxCounterAtom, (oldValue) => oldValue + 1)
