import { find, compact, isEqual, uniq } from 'lodash'
import {
  atom,
  selector,
  selectorFamily,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useRecoilValueLoadable,
  atomFamily,
  useRecoilRefresher_UNSTABLE,
} from 'recoil'

import { getFollowedUserTagsByUid } from 'common/api/helpers'
import { resetRecoil } from 'recoil-nexus'
export {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  resetRecoil,
  useRecoilValueLoadable,
}

import { getDomainPacket, getTopHashtagsForUserId } from 'universal/api'
import { getUserById } from 'universal/hooks'

import {
  syncedWithExtStorageEffect,
  updateBrowserIconOnChangeEffect,
  loadAuthAtomDefault,
  activeUidsAtomDefaultGetter,
} from 'universal/recoil'

import { makeOptionFromHashtag } from 'cc/Form/HashtagSelectField'
import { targetsForAddress, ruleReviewMatches } from 'common/logic'

// --- Core: Current context (tab + address) ---

export const currentAtom = atom({
  key: 'currentContext',
  default: {},
  effects: [
    syncedWithExtStorageEffect('ext/currentContext'),
    ({ onSet }) => {
      onSet(async (newValue, oldValue, isReset) => {
        // console.log('CURRENT CONTEXT:', { newValue, oldValue, isReset })
        if (!oldValue.tabId) return // Initial load, don't clear mode default yet [TODO: confirm - may have to, as draft seems to be persisting in ext]

        // Note: could get fancy here and consider e.g. keeping the same mode persisted across different tabs on same domainKey... but may have different _page_ reviews if URL differs at all
        if (isReset || !isEqual(oldValue, newValue)) {
          resetRecoil(modeAtom)
          resetRecoil(writingContextAtom)
          resetRecoil(draftDomainReviewAtom)
          resetRecoil(draftPageReviewAtom)
        }
      })
    },
  ],
})

// --- Core: UI Mode ---

export const WRITING_MODE = 'WRITING'
export const READING_MODE = 'READING'

export const modeAtom = atom({
  key: 'mode',
  default: selector({
    key: 'modeAtom/Default',
    get: async ({ get }) => {
      const { domainKey } = get(currentAtom)
      if (!domainKey) return // No point setting anything, neither view will show in UI

      // If we've started a review, default to write mode
      if (get(draftDomainReviewAtom)?.isDirty) return WRITING_MODE
      if (get(draftPageReviewAtom)?.isDirty) return WRITING_MODE

      const domainPackets = get(relevantDomainPacketsSelectorFamily(domainKey))
      if (domainPackets?.length) return READING_MODE // If we have reviews to show, show 'em

      return WRITING_MODE // Otherwise, default to write mode
    },
  }),
  effects: [syncedWithExtStorageEffect('ext/mode')],
})

// --- Core: Auth ---

export const authAtom = atom({
  key: 'auth',
  default: selector({
    key: 'auth/Default',
    get: loadAuthAtomDefault,
  }),
  effects: [
    // ({ onSet }) => {
    //   onSet(async (newValue, oldValue, isReset) => {
    //     if (isReset || oldValue?.id !== newValue?.id) {
    //       console.log('Auth atom changed -- reseting active UIDs')
    //       // resetRecoil(activeUidsAtom)
    //       // TODO: only if
    //     }
    //   })
    // },
  ],
})

// --- Options page: settings ---
export const extSettingsAtom = atom({
  key: 'extSettingsAtom',
  default: {},
  effects: [syncedWithExtStorageEffect('ext/settings')],
})

// --- Core: what reviews have activeUids left on given domainKey? ---
export const relevantDomainPacketsSelectorFamily = selectorFamily({
  key: 'relevantDomainPackets',
  get:
    (domainKey) =>
    async ({ get }) => {
      if (!domainKey) return []

      const activeUserIds = get(activeUidsAtom) || []

      // TODO: maybe need to be able to forceRefresh (when user saved a pageReview)

      const data = await Promise.all(
        activeUserIds.map((userId) =>
          getDomainPacket({
            domainKey,
            userId,
            forceRefresh: false,
          }),
        ),
      )

      return compact(data)
    },
})

// --- Read mode: active users ---
export const followedUsersAtom = atom({
  key: 'followedUsers',
  default: [],
  effects: [
    async ({ onSet, setSelf, trigger, getPromise }) => {
      if (trigger === 'get') {
        const currentUser = await getPromise(authAtom)
        const followedUserTags = (await getFollowedUserTagsByUid(currentUser.id)) || {}
        const followedUserUids = Object.keys(followedUserTags)

        const followedUsers = await Promise.all(followedUserUids.map(getUserById))

        // Adding .tagged method to the stored followedUsers
        const me = { tagged: [], ...currentUser }
        followedUsers.forEach((user) => {
          user.tagged = followedUserTags[user.id] || []
        })

        setSelf(compact([me, ...followedUsers]))
      }

      // TODO: set activeUids to all users when set of followed users changes (maybe just if getting bigger?)
      // TODO: why couldn't get this working?
      // onSet(async (newValue, oldValue, isReset) => {
      //   console.log('!!! followedUsers Atom was set to:', {
      //     newValue,
      //     oldValue,
      //     isReset,
      //   })
      // })
    },
  ],
})

export const activeUidsAtom = atom({
  key: 'activeUids',
  default: selector({
    key: 'activeUids/Default',
    get: activeUidsAtomDefaultGetter('ext/activeUids', followedUsersAtom),
  }),
  effects: [
    syncedWithExtStorageEffect('ext/activeUids'),

    // When activeUids change, should update the browser badge to match
    updateBrowserIconOnChangeEffect,
  ],
})

// --- Write mode: draft review ---

export const draftDomainReviewAtom = atom({
  key: 'draftDomainReview',
  default: {},
  effects: [syncedWithExtStorageEffect('ext/draftDomainReview')],
})

export const draftPageReviewAtom = atom({
  key: 'draftPageReview',
  default: {},
  effects: [syncedWithExtStorageEffect('ext/draftPageReview')],
})

export const draftReviewSelector = selector({
  key: 'draftReview',
  get: ({ get }) => {
    return get(writingContextAtom) === 'page' ? get(draftPageReviewAtom) : get(draftDomainReviewAtom)
  },
})

export const writingContextAtom = atom({
  key: 'writingContext',
  default: selector({
    key: 'writingContextAtom/Default',
    get: async ({ get }) => {
      // If user has started reviewing the page, default to opening that form
      const draftPageReview = get(draftPageReviewAtom)
      if (draftPageReview) return 'page'

      // If user has already reviewed the domain, default to current page review (whether or not it's been saved)
      const { domainKey, pageKey } = get(currentAtom)
      if (!domainKey) return // No point setting anything, neither view will show in UI

      const currentUser = get(authAtom)
      if (!currentUser) return

      const currentUsersDomainPacket = await getDomainPacket({
        domainKey,
        userId: currentUser.id,
      })

      // If already left a domain review, default to page review whether we've already left one or not
      return currentUsersDomainPacket?.domainReview ? 'page' : 'domain'
    },
  }),
  effects: [
    ({ onSet }) => {
      onSet(async (newValue, oldValue, isReset) => {
        resetRecoil(reviewToShareAtom)
      })
    },
    syncedWithExtStorageEffect('ext/writingContextAtom'),
  ],
})

// domainPacket == currentUser's (hydrated) review of the domain + any pages reviews
export const currentUserDomainPacketSelector = selector({
  key: 'currentUserDomainPacket',
  get: async ({ get }) => {
    const { domainKey } = get(currentAtom)
    if (!domainKey) return

    const currentUser = get(authAtom)
    if (!currentUser) return

    return await getDomainPacket({
      domainKey,
      userId: currentUser.id,
    })
  },
})

const currentDomainReviewSelector = selector({
  key: 'currentDomainReview',
  get: async ({ get }) => {
    const domainPacket = get(currentUserDomainPacketSelector)
    if (!domainPacket?.domainReview) return
    if (domainPacket.domainReview.placeholder) return

    return domainPacket.domainReview
  },
})

const currentPageReviewSelector = selector({
  key: 'currentPageReview',
  get: async ({ get }) => {
    const { pageKey } = get(currentAtom)
    if (!pageKey) return

    const domainPacket = get(currentUserDomainPacketSelector)
    if (!domainPacket) return

    const matchingReview = domainPacket.pageReviews[pageKey]
    if (!matchingReview) return

    // TODO: check against rule reviews? Probably start using the scoring logic helpers...

    return matchingReview
  },
})

// either pageReview or the domainReview part of the hydrated domainPacket, depending on writingContext
export const currentReviewSelector = selector({
  key: 'currentReview',
  get: async ({ get }) => {
    const writingContext = get(writingContextAtom)
    return get(writingContext === 'page' ? currentPageReviewSelector : currentDomainReviewSelector)
  },
})

const currentUserHashtagsSelector = selector({
  key: 'currentUserHashtags',
  get: async ({ get }) => {
    const { id } = get(authAtom) || {}
    if (!id) return []

    const tags = (await getTopHashtagsForUserId(id)) || []

    return tags.map(({ id }) => id)
  },
})

export const currentHashtagOptionsSelector = selector({
  key: 'currentHashtagOptions',
  get: async ({ get }) => {
    const { id } = get(authAtom) || {}
    if (!id) return []

    const { hashtags: domainHashtags } = get(currentDomainReviewSelector) || {}
    const { hashtags: pageHashtags } = get(currentPageReviewSelector) || {}
    const userHashtags = get(currentUserHashtagsSelector)

    const hashtagOptions = uniq([...(domainHashtags || []), ...(pageHashtags || []), ...(userHashtags || [])]).map(
      makeOptionFromHashtag,
    )

    return hashtagOptions
  },
})

export const reviewToShareAtom = atom({
  key: 'reviewToShare',
  default: selector({
    key: 'reviewToShare/Default',
    get: async ({ get }) => {
      const { isDirty: hasPendingDirtyDraft } =
        get(writingContextAtom) === 'page' ? get(draftPageReviewAtom) : get(draftDomainReviewAtom)

      // If the user has edited a draft of this, default to showing the form rather than the share screen
      if (hasPendingDirtyDraft) return null

      // If the packet has a meta it's actually been saved, so show the share screen
      const review = get(currentReviewSelector)

      if (review?.meta) return review
      return null
    },
  }),
})

export const readingContextAtom = atom({
  key: 'readingContext',
  default: selector({
    key: 'readingContextAtom/Default',
    get: async ({ get }) => {
      const { domainKey, pageKey, address } = get(currentAtom)
      if (!domainKey || !pageKey) return 'domain' // Moot point, but giving a valid value

      const reviews = get(relevantDomainPacketsSelectorFamily(domainKey))
      const { targetForRuleReview, targetPageKey } = targetsForAddress(address)

      const pageReview = find(reviews, ({ pageReviews }) => pageReviews && pageReviews[targetPageKey])

      if (pageReview) return 'page'

      const ruleReview = find(
        reviews,
        ({ ruleReviews }) =>
          ruleReviews?.length && find((ruleReview) => ruleReviewMatches(ruleReview, targetForRuleReview)),
      )

      return ruleReview ? 'page' : 'domain'
    },
  }),
})
