import { Fragment } from 'react'
import { cn } from 'common/utils'
import { compact, every, some, startsWith, endsWith, capitalize, isEmpty } from 'lodash'

export const humanizeVote = (vote = 0) => {
  if (vote > 0) return 'positive'
  if (vote < 0) return 'negative'
  return 'neutral'
}

class RuleVariant {
  constructor(value, { matcher, label } = {}) {
    if (!value || !matcher) {
      throw 'RuleVariants must have a defined value and matcher'
    }

    this.value = value
    this.label = label || value
    this.matcher = matcher
  }
}

// TODO: add support for "any of"/arrays in rule
const SUPPORTED_RULE_VARIANTS = {
  equals: new RuleVariant('equals', {
    label: 'is exactly',
    matcher: (noun, input) => input === noun,
  }),
  prefix: new RuleVariant('prefix', {
    label: 'starts with',
    matcher: (noun, input) => startsWith(input, noun),
  }),
  suffix: new RuleVariant('suffix', {
    label: 'ends with',
    matcher: (noun, input) => endsWith(input, noun),
  }),
  contains: new RuleVariant('contains', {
    matcher: (noun, input) => input.indexOf(noun) > -1,
  }),
  any: new RuleVariant('any', {
    label: 'is anything',
    matcher: () => true,
  }),
  blank: new RuleVariant('blank', {
    label: 'is blank',
    matcher: (_, input) => !input?.length,
  }),
}

export const VerbOptions = Object.entries(SUPPORTED_RULE_VARIANTS).map(([value, { label }]) => ({ value, label }))

// TODO: eventually expand to include params (different set of matchers)?
export const SUPPORTED_RULE_FIELDS = ['path', 'subdomain']

const normalizeRulePart = (part) => {
  if (!part?.verb) return null
  if (part.verb === 'any' || isEmpty(part.noun)) return null // not actually applying any restriction === same as not existing
  return part
}

// run isEqual *after* compacting falsy noun fields ({verb: 'any', noun: ""} should equalish {verb: 'any'})
const isEqualish = (rulePartA, rulePartB) => {
  if (!rulePartA && !rulePartB) return true
  if (!rulePartA || !rulePartB) return false
  if (rulePartA.verb !== rulePartB.verb) return false
  if (rulePartA.noun !== rulePartB.noun) return false

  return true
}

export const rulesAreEquivalent = (a, b) =>
  a &&
  b &&
  every(SUPPORTED_RULE_FIELDS, (field) => isEqualish(normalizeRulePart(a[field]), normalizeRulePart(b[field])))

// TODO: use this to prevent creating duplicate rules
export const ruleExistsInlist = (ruleReview, list) => some(list, (listItem) => rulesAreEquivalent(listItem, ruleReview))

// TODO: make more performant
export const ruleListsEqualish = (ruleListA, ruleListB) =>
  ruleListA.length === ruleListB.length &&
  every(ruleListA, (ruleFromA) => ruleExistsInlist(ruleFromA, ruleListB)) &&
  every(ruleListB, (ruleFromB) => ruleExistsInlist(ruleFromB, ruleListA))

// Note: expects a pre-parsed URL w/ path and subdomain fields
// TODO: We don't currently support the search component of URLs (?foo=bar), but we do parse it from the URL
// and it's available here if we want to e.g. append it to the path for exact matches (need to clarify from UI what user intends)
export const ruleReviewMatches = (ruleReview = {}, parsedURL) =>
  ruleReview.rule &&
  every(SUPPORTED_RULE_FIELDS, (fieldName) => ruleFieldMatches(ruleReview.rule[fieldName], parsedURL[fieldName]))

const ruleFieldMatches = (ruleField, input) => {
  if (!ruleField) return true // If no restriction present (e.g. for path or subdomain) default to a match all

  const ruleVariant = SUPPORTED_RULE_VARIANTS[ruleField.verb]
  if (!ruleVariant) {
    console.warn(
      'ruleFieldMatches given invalid rule structure or unknown variant -- defaulting to false. Given: ',
      ruleField,
    )
    return false
  }

  return ruleVariant.matcher(ruleField.noun, input)
}

const getVariantForFieldDisplay = (rule, field) => {
  if (!rule || !rule[field]) return null
  if (rule[field].verb === 'any') return null

  return SUPPORTED_RULE_VARIANTS[rule[field]?.verb]
}

const humanizeRuleReviewField = (rule, field, Tag = 'span') => {
  const fieldTitle = capitalize(field)
  const ruleVariant = getVariantForFieldDisplay(rule, field)
  if (!ruleVariant) return null

  return (
    <Tag className="mb-0">
      <strong>{fieldTitle}</strong>
      <span> {ruleVariant.label} </span>
      <em className="text-monospace">{rule[field].noun}</em>
    </Tag>
  )
}

const humanizeRuleReviewFields = (rule, Tag = 'li', joinWith = null) => {
  const fields = compact(SUPPORTED_RULE_FIELDS.map((field) => humanizeRuleReviewField(rule, field, Tag)))
  const lastFieldIdx = fields.length - 1

  return fields.map((field, idx) => (
    <Fragment key={idx}>
      {field}
      {joinWith && idx < lastFieldIdx ? joinWith : null}
    </Fragment>
  ))
}

export const HumanizedRuleReview = ({ ruleReview, className, Tag = 'ul', fieldTag = 'li', joinWith }) =>
  ruleReview ? (
    <Tag className={cn(className, Tag === 'ul' && 'list-disc list-inside ml-2')}>
      {humanizeRuleReviewFields(ruleReview.rule, fieldTag, joinWith)}
    </Tag>
  ) : null

export const HumanizedRuleReviewText = ({ ruleReview, className }) => (
  <HumanizedRuleReview ruleReview={ruleReview} className={className} Tag="span" fieldTag="span" joinWith=" and " />
)

export const forRulesTest = {
  ruleFieldMatches,
  humanizeRuleReviewField,
}
