// Wrappers around the core get/use methods from swr-firestore.
// In extension, ONLY to be used from background (wrapped in another caching layer)
import {
  getCollection as origGetCollection,
  useCollection as origUseCollection,
  getDocument as origGetDocument,
  useDocument as origUseDocument,
} from '@nandorojo/swr-firestore'

import { useMemo } from 'react'

// Note: These are the only options for getFoo; useFoo also accepts listen, as well as refreshInterval and other undelrying-SWR-library options
const SwrFirestoreKeys = [
  'parseDates',
  'ignoreFirestoreDocumentSnapshotField',
  'listen',
]
const DefaultSwrFirestoreConfig = {
  parseDates: ['meta.createdAt', 'meta.updatedAt'],
  ignoreFirestoreDocumentSnapshotField: true,
}

const swrOptions = ({ parseDates, ...opts } = {}) => ({
  ...DefaultSwrFirestoreConfig,
  ...opts,
  parseDates: parseDates
    ? [...DefaultSwrFirestoreConfig.parseDates, ...parseDates]
    : DefaultSwrFirestoreConfig.parseDates,
})

// Note: swr-firestore AND use-swr options (e.g. parseDates, listen + SWR) combined in second arg (matches underlying lib)
export const useDocument = (path, opts) => {
  // console.log(`[useDocument] ${path}`, swrOptions(opts))
  return origUseDocument(path, swrOptions(opts))
}

// Note: swr-firestore AND use-swr options (e.g. parseDates, listen + SWR) combined in second arg (note does NOT match underlying lib, which has
// all swr-firestore options (e.g. parseDates, listen) merged in with query, while use-swr options are a separate third argument)
export const useCollection = (path, query = {}, opts = {}) => {
  // For some reason the swr-firestore library combines useSWR + swr-firestore options in the useDocument case, but
  // (explicitly *against* what they claim in the documentation: https://github.com/nandorojo/swr-firestore/blob/b5f9a09b13447457526fcf2ce194aa0431130736/src/hooks/use-swr-collection.ts#L291)
  // they have swr-firestore options merged into the query field for the useCollection case.

  // We are standardizing on the last argument (2nd for useDocument, 3rd for useCollection) containing both useSWR and swr-firestore options,
  // while the second argument for useCollection is purely query-related.

  // Pull the SwrFirestoreKeys out of the options field, where I passed them because they're options, and put them in the query field, where
  // the underlying library wants them for some inexplicable reason.
  const { libQuery, libOpts } = useMemo(() => {
    const libQuery = query
    const libOpts = swrOptions(opts)
    SwrFirestoreKeys.forEach((key) => {
      if (libOpts[key]) {
        libQuery[key] = libOpts[key]
        delete libOpts[key]
      }
    })

    return { libQuery, libOpts }
  }, [query, opts]) // TODO: do I need the usememocomparison hook thing?

  return origUseCollection(path, libQuery, libOpts)
}

export const getDocument = async (path, opts) => {
  if (!path) return null // Upstream handles this for useDocument, but not get
  return origGetDocument(path, swrOptions(opts))
}

export const getCollection = async (path, query = {}, opts) => {
  if (!path) return null // Upstream handles this for useCollection, but not get
  return origGetCollection(path, query, swrOptions(opts))
}
