// TODO: use this everywhere - web and ext
import { mapValues, uniq } from 'lodash'
import { useMemo } from 'react'

const normalizeHashtagPacket = (rawPacket) => {
  const {
    numDomains = 0,
    numDomainReviews = 0,
    numPageReviews = 0,
    numUsers = 0,
    createdAt = new Date(), // Not super happy about this as a default, but we build the OG meta description around it being present
    updatedAt,
  } = rawPacket?.meta || {}
  return {
    numDomains,
    numDomainReviews,
    numPageReviews,
    numUsers,
    createdAt,
    updatedAt,
  }
}

const expandReviewRuleForForm = (rawRule) => {
  const rule = { ...rawRule }
  if (!rule.path) rule.path = { verb: 'any', noun: '' }
  if (!rule.subdomain) rule.subdomain = { verb: 'blank', noun: '' }
  // TODO: params?

  return rule
}

const noNounNeeded = (verb) => ['any', 'blank'].indexOf(verb) !== -1

// No nouns needed if verb is 'any'... and actually "no restriction" doesn't need to be stored at all - defaults to 'any'
export const condenseReviewRuleForStorage = (rule) => {
  if (!rule) return null
  if (rule.rule)
    return console.warn(
      '[condenseReviewRuleForStorage] received a review packet of some sort, expected just the rule portion',
      rule,
    )

  if (noNounNeeded(rule.subdomain?.verb)) delete rule.subdomain.noun
  if (noNounNeeded(rule.path?.verb)) delete rule.path.noun
  // TODO: params?

  // TODO: but be sure this throws validation errors before actually saving...
  if (Object.keys(rule).length === 0) return null

  return rule
}

export const normalizeReview = (rawPacket) => {
  if (!rawPacket || rawPacket.exists === false) return null
  const { vote = 0, comment = '', hashtags, meta = {}, rule } = rawPacket

  const review = { vote, comment, hashtags: uniq(hashtags || []) }
  if (rule) review.rule = rule

  review.meta = Object.assign({}, meta)
  review.meta.version = review.meta.version || 0
  const { pageKey } = meta

  const isDomainReview = !pageKey && !rule
  if (isDomainReview) {
    review.meta.numPageReviews = review.meta.numPageReviews || 0
    review.meta.numRuleReviews = review.meta.numRuleReviews || 0
  }

  // From trying to share a draft review
  if (rawPacket?.loading) review.loading = true
  if (rawPacket?.draft) review.draft = true

  return review
}

const defaultReviewSkeleton = {
  vote: 0,
  comment: '',
  hashtags: [],
  draft: true,
}

export const normalizeReviewWithDefault = (rawPacket) => normalizeReview(rawPacket) || defaultReviewSkeleton

export const normalizeReviewForForm = (rawPacket) => {
  const { meta, ...review } = normalizeReviewWithDefault(rawPacket)
  if (review.rule) review.rule = expandReviewRuleForForm(review.rule)

  return review
}

export const buildDomainPacket = ({ domainKey, userId, domainReview, pageReviews, ruleReviews }) => {
  if (!domainReview && !ruleReviews?.length && !Object.keys(pageReviews || {}).length) return null

  if (domainReview?.meta) {
    domainReview.meta.numPageReviews = domainReview.meta.numPageReviews || Object.keys(pageReviews || {}).length

    domainReview.meta.numRuleReviews = domainReview.meta.numRuleReviews || ruleReviews?.length || 0
  }

  return {
    meta: { domainKey, userId },
    domainReview: normalizeReview(domainReview),
    ruleReviews: (ruleReviews || []).map(normalizeReview), // ruleReviews: array of ruleReviews
    pageReviews: mapValues(pageReviews || {}, normalizeReview), // pageReviews: map of pageKey: pageReview
  }
}

export const useNormalizedHashtagPacket = (hashtagPacket) =>
  useMemo(() => normalizeHashtagPacket(hashtagPacket), [hashtagPacket])

export const useNormalizedReviewPacket = (review) => useMemo(() => normalizeReview(review), [review])
