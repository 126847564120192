// Logic originally from _app, but also applies to other pages (e.g. rending auth dynamically in a landing page header)
import { FuegoProvider } from '@nandorojo/swr-firestore'
import fuego from 'utils/swrFirestore/fuego'
import { Toaster } from 'react-hot-toast'
import appToastOptions from 'common/config/toast'
import { RecoilRoot } from 'recoil'
import RecoilNexus from 'recoil-nexus'
import { Transition } from '@headlessui/react'
import { useCallback, useEffect, useState } from 'react'

import { getUserById } from 'utils/hooks'
import { useSetRecoilState, authAtom } from 'common/recoil'
import { useRouter } from 'next/router'
import Link from 'components/Link'
import routes from 'common/routes'

import bext from 'utils/bext'
import { doExtLogIn } from 'lib/auth'
import { handle } from 'common/utils'
import Cookies from 'js-cookie'
import { useCurrentBrowserButton } from 'components/marketing/InstallExtensionButtonset'
import InjectFonts from 'utils/InjectFonts'

const LoggedInWithoutExtensionInstalledBanner = ({
  user,
  closeBanner,
  noThanks,
  currentBrowserConfig,
  CurrentBrowserButton,
}) => (
  <div className="bg-blue-100 p-4 flex justify-between items-center space-x-5 border-b">
    <div className="text-blue-900">
      <div className="text-base">Hey there, {handle(user)}!</div>
      <div className="text-sm">
        It looks like you don't yet have the extension installed in this
        browser.
      </div>
    </div>
    <div className="flex space-x-2" onClick={closeBanner}>
      {currentBrowserConfig?.href && CurrentBrowserButton ? (
        <CurrentBrowserButton className="bg-blue-500 hover:bg-blue-600" />
      ) : (
        <Link
          href={routes.aboutExtensionPath}
          text="About Extension"
          className="flex items-center justify-center text-center px-2 py-1 text-sm text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700"
        />
      )}
      <Link
        onClick={noThanks}
        text="Not Now"
        className="flex items-center justify-center text-center px-2 py-1 text-sm text-gray-900 bg-gray-300 border border-transparent rounded-md hover:bg-gray-400"
      />
    </div>
  </div>
)
const signInExtIfNeeded = async () => {
  const installedInfo = await bext.isExtensionInstalled()

  if (installedInfo.version && !installedInfo.uid) {
    await doExtLogIn({ wasSignedOut: true })
  }

  return installedInfo
}

// Another layer of nesting, b/c this has to run *inside* a RecoilRoot
const WrappedInAuth = ({ children }) => {
  const setAuth = useSetRecoilState(authAtom)
  const router = useRouter()

  const [banner, setBanner] = useState(null)
  const [isOpen, setIsOpen] = useState(false)

  const declinedExt = Cookies.get('declinedExtensionBanner')
  const closeBanner = useCallback(() => setIsOpen(false), [])
  const noThanks = useCallback(() => {
    closeBanner()
    Cookies.set('declinedExtensionBanner', true, { path: '/', expires: 365 })
  }, [closeBanner])

  const { currentBrowserConfig, CurrentBrowserButton } =
    useCurrentBrowserButton()

  useEffect(() => {
    // TODO: should we be removing the listener, e.g. wrap _App in this?
    fuego.auth().onAuthStateChanged(async (auth) => {
      if (!auth?.uid) {
        setAuth(null)
        return
      }

      // TODO: how to cache this locally? No need to fetch self on every page load...
      const user = await getUserById(auth.uid)
      setAuth(user)
      const currentPath = router.asPath
      const installedInfo = await signInExtIfNeeded()

      // TODO: add a way to bypass by query param (this doesn't seem to actually be reading the query param as expected
      const { showBanner } = router.query

      if ((showBanner || !declinedExt) && !installedInfo) {
        // Don't show banner on homepage
        if (
          currentPath !== '/' &&
          currentPath !== '/about/extension' &&
          currentPath !== '/ext/auth'
        ) {
          console.log('Showing banner on:', currentPath)
          setBanner(
            <LoggedInWithoutExtensionInstalledBanner
              user={user}
              closeBanner={closeBanner}
              noThanks={noThanks}
              currentBrowserConfig={currentBrowserConfig}
              CurrentBrowserButton={CurrentBrowserButton}
            />,
          )
          setIsOpen(true)
        }
      }
    })
  }, [setAuth, closeBanner, declinedExt, noThanks, router.asPath])

  // TODO: why doesn't this transition in smoothly in a nice slideDown?
  return (
    <div className="flex flex-col">
      <Transition
        show={isOpen}
        enter="transform transition ease-in-out duration-1000"
        enterFrom="opacity-0 -translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transform transition ease-in-out duration-1000"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 -translate-y-full"
      >
        {banner}
      </Transition>
      {children}
    </div>
  )
}

const renderFullyWrappedComponent = (Component, pageProps, Layout) => {
  return (
    <>
      <InjectFonts />
      <RecoilRoot>
        <RecoilNexus />

        <FuegoProvider fuego={fuego}>
          <Toaster {...appToastOptions} />
          <WrappedInAuth>
            {Layout ? ( // Note: app defaults to defaultLayout; when called elsewhere, we probably don't need layout so only use default if explicitly passed in
              <Layout Component={Component} pageProps={pageProps} />
            ) : (
              <Component {...pageProps} />
            )}
          </WrappedInAuth>
        </FuegoProvider>
      </RecoilRoot>
    </>
  )
}

export default renderFullyWrappedComponent
