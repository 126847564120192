import { setFuego } from '@nandorojo/swr-firestore'
import firebaseConfig from 'common/config/firebase'

import firebase from 'firebase/app'

import 'firebase/analytics'

import 'firebase/auth'
import 'firebase/firestore'

// Wrap up our firebase initialization in a convenient object for use-firestore
// See: https://github.com/nandorojo/swr-firestore/issues/59#issuecomment-719950071
class Fuego {
  constructor(config) {
    const alreadyInitialized = firebase.apps.length
    const db = !alreadyInitialized
      ? firebase.initializeApp(config).firestore()
      : firebase.app().firestore()
    const auth = firebase.auth

    // Manage emulation
    // if (!alreadyInitialized && process.env.NODE_ENV === 'development') {
    //   console.log('[Connecting to Firebase Emulator]')
    //   db.useEmulator('localhost', 8080)
    //   auth().useEmulator('http://localhost:9099')
    // }

    this.db = db
    this.auth = auth

    // TODO: enable if we use them... I *think* OK to leave off if not?
    // this.functions = firebase.functions
    // this.storage = firebase.storage

    // Here down is our own customization, not required by nandorojo/swr-firestore
    this.firestore = firebase.firestore // Used for e.g. .FieldPath.documentId()
  }
}

const fuego = new Fuego(firebaseConfig)
setFuego(fuego)
export default fuego
