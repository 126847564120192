// NOTE: Universal because it shares the same interface with the component of the same name in the extension codebase.
import { useLinkCallback } from 'common/hooks'
import Link from 'components/Link'
export default Link
import { useRouter } from 'next/router'

// Because in the case of a review w/ markdown, which can include links, we can't wrap it in an *actual* link, so
// we need something that'll act similarly. NOTE: up to caller to pass in className w/ a e.g. hover:bg-gray-100
export const UniversalClickableDiv = ({ href, className, children }) => {
  const router = useRouter()

  const handleClick = useLinkCallback(() => {
    router.push(href)
  })

  return (
    <div className={className} onClick={handleClick}>
      {children}
    </div>
  )
}
