import { cn } from 'common/utils'

const BaseClasses = 'p-3'

const VariantClasses = {
  warning: 'bg-yellow-100 text-yellow-800',
  info: 'bg-blue-100 text-blue-900',
}

const Alert = ({ variant = 'warning', className, children }) => (
  <div className={cn(className, BaseClasses, VariantClasses[variant])}>{children}</div>
)

const HeaderBaseClasses = 'font-semibold text-xl mb-2'

const HeaderVariantClasses = {
  warning: 'text-yellow-800',
  info: 'text-blue-800',
}

Alert.Heading = ({ variant = 'warning', className, children }) => (
  <div className={cn(className, HeaderBaseClasses, HeaderVariantClasses[variant])}>{children}</div>
)
Alert.Heading.displayName = 'Alert.Heading'

export default Alert
