import { forwardRef } from 'react'
import Select from 'react-select'

import { useController } from 'react-hook-form'
import CreatableSelectSubfield from './CreatableSelectSubfield'

import useSelectByValue from './useSelectByValue'

const NoncreatableSelectInputByValue = forwardRef(
  ({ onChange, value: valueAsValue, options, isMulti, ...props }, ref) => {
    const { valueAsOption, onChangeValueAdapter } = useSelectByValue({
      isMulti,
      options,
      onChange,
      valueAsValue,
    })

    return (
      <Select
        {...props}
        isMulti={isMulti}
        onChange={onChangeValueAdapter}
        value={valueAsOption}
        options={options}
        ref={ref}
      />
    )
  },
)

const SelectByValue = forwardRef(({ creatable, ...props }, ref) =>
  creatable ? (
    <CreatableSelectSubfield {...props} ref={ref} />
  ) : (
    <NoncreatableSelectInputByValue {...props} inputRef={ref} />
  ),
)

// note: validationRules are e.g. {required: true} - see inputs to useController https://react-hook-form.com/api/usecontroller/
const SelectField = ({ name, validationRules, ...props }) => {
  const {
    field: { ref, ...inputProps },
  } = useController({ name, rules: validationRules })

  // TODO: use errors etc to style properly
  //         className={cn(className, error && 'is-invalid')}
  /* Note: touched is always false b/c controlled... if we really need it can add manual tracking */
  //       {error && (
  //         <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
  //       )}
  return <SelectByValue {...props} {...inputProps} ref={ref} />
}

export default SelectField
