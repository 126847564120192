/* eslint-disable @next/next/no-img-element */
import { useMemo } from 'react'
import Link from 'next/link'
import { cn } from 'common/utils'
import {
  ChromeIcon,
  FirefoxIcon,
  SafariIcon,
  OperaIcon,
  EdgeBrowserIcon,
} from 'cc/icons'
import Logo from 'cc/Logo'
import routes from 'common/routes'
import config from 'common/config'
import { useCurrentBrowser } from 'utils/hooks'

const LearnMoreButton = () => (
  <Link
    href={routes.aboutExtensionPath}
    rel="follow"
    className="flex items-center px-5 py-3 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none"
  >
    <Logo className="w-10" />

    <span className="flex flex-col items-start ml-4 leading-none">
      <span className="mb-1 text-xs text-gray-600 uppercase">how it works</span>
      <span className="title-font text-gray-800 whitespace-nowrap">
        <span className="font-bold">voyd</span>.center
      </span>
    </span>
  </Link>
)

const ExtensionLink = ({
  tag = 'a',
  active,
  href,
  icon,
  store,
  label,
  className,
}) => {
  const Tag = tag
  const Icon = icon

  return (
    <Tag
      href={href}
      rel="follow noreferrer"
      target="_blank"
      className={cn(
        'flex items-center px-5 py-3 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none',
        active && 'scale-150 font-bold',
        className,
      )}
    >
      {icon && (
        <Icon
          className={cn('h-10 w-10', active && 'min-w-max')}
          title={store}
        />
      )}
      <span className="flex flex-col items-start ml-4 leading-none">
        <span className="mb-1 text-xs text-gray-600 uppercase">{label}</span>
        <span className="font-medium title-font">{store}</span>
      </span>
    </Tag>
  )
}

const BrowserButtonConfigs = Object.entries({
  chrome: {
    store: 'Chrome Web Store',
    label: 'Get it on',
    icon: ChromeIcon,
  },
  firefox: {
    store: 'Firefox Add-ons',
    label: 'Coming soon',
    icon: FirefoxIcon,
  },
  safari: {
    store: 'Safari Extensions',
    label: 'Not yet available',
    icon: SafariIcon,
  },
  opera: {
    store: 'Opera addons',
    label: 'Not yet available',
    icon: OperaIcon,
  },
  edge: {
    store: 'Edge Add-Ons',
    label: 'Not yet available',
    icon: EdgeBrowserIcon,
  },
}).reduce((acc, [browser, btnConfig]) => {
  const href = config.urls.extensionDownload[browser]

  if (href) {
    btnConfig.href = href
  } else {
    btnConfig.tag = 'div'
    btnConfig.className = 'cursor-help'
  }

  acc[browser] = btnConfig
  return acc
}, {})

const BrowserButtons = Object.entries(BrowserButtonConfigs).reduce(
  (acc, [browser, btnConfig]) => {
    const Component = (props) => <ExtensionLink {...btnConfig} {...props} />
    acc[browser] = Component
    return acc
  },
  {},
)

export const useCurrentBrowserButton = () => {
  const currentBrowser = useCurrentBrowser()
  const CurrentBrowserButton = useMemo(
    () => BrowserButtons[currentBrowser],
    [currentBrowser],
  )
  const currentBrowserConfig = useMemo(
    () => BrowserButtonConfigs[currentBrowser],
    [currentBrowser],
  )

  return { currentBrowser, CurrentBrowserButton, currentBrowserConfig }
}

const InstallExtensionButtonset = ({ hideLearnMore }) => {
  const { currentBrowser, CurrentBrowserButton } = useCurrentBrowserButton()

  return (
    <div>
      <div className="flex flex-wrap justify-center items-center space-x-4 space-y-4 md:space-x-10 scale-150 mb-16 mt-4 mx-10">
        <div />
        {currentBrowser && CurrentBrowserButton && <CurrentBrowserButton />}

        {hideLearnMore ? null : <LearnMoreButton />}
      </div>

      <div className="flex flex-wrap justify-center space-x-4 space-y-4">
        <div />
        {Object.keys(BrowserButtons).map((browser) => {
          const Button = BrowserButtons[browser]
          return currentBrowser === browser ? null : (
            <Button key={browser} active={currentBrowser === browser} />
          )
        })}
      </div>
    </div>
  )
}

export default InstallExtensionButtonset
