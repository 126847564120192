import {
  FiEdit,
  FiPlus,
  FiTrash2,
  FiMessageSquare,
  FiHelpCircle,
  FiX,
  FiChevronRight,
  FiAlertTriangle,
  FiClipboard,
  FiLink,
  FiExternalLink,
  FiMoreVertical,
  FiBell,
  FiMenu,
  FiHome,
  FiUsers,
  FiCompass,
  FiClock,
  FiEye,
  FiAperture,
  FiChrome,
  FiShare2,
  FiTriangle,
} from 'react-icons/fi'
import { GiBrainstorm } from 'react-icons/gi'
import { GrEdge } from 'react-icons/gr'
import { VscPlug, VscFeedback, VscSettings } from 'react-icons/vsc'
import { SiSafari } from 'react-icons/si'
import { MdEmail, MdSchool } from 'react-icons/md'
import { IoMdSync } from 'react-icons/io'
import { FaFirefoxBrowser, FaOpera, FaFacebook, FaTwitter } from 'react-icons/fa'
import { AiFillInstagram } from 'react-icons/ai'
import ConditionalWrap from 'cc/ConditionalWrap'
import UniversalLink from 'universal/UniversalLink'
import { cn } from 'common/utils'

const DefaultIconLinkClassName = 'text-gray-500 hover:text-gray-700'

const makeIcon =
  (Component, { linkClassName, ...staticProps } = {}) =>
  ({ href, onClick, className, ...props }) =>
    (
      <ConditionalWrap
        condition={href || onClick}
        wrap={(inner) => (
          <UniversalLink href={href} onClick={onClick} className={cn(linkClassName || DefaultIconLinkClassName)}>
            {inner}
          </UniversalLink>
        )}
      >
        <Component {...staticProps} {...props} className={cn(className)} />
      </ConditionalWrap>
    )

// Core interactivity
export const AddIcon = makeIcon(FiPlus, { title: 'Add' })
export const EditIcon = makeIcon(FiEdit, { title: 'Edit' })
export const DeleteIcon = makeIcon(FiTrash2, {
  title: 'Delete',
  linkClassName: 'text-gray-500 hover:text-red-800',
})

// Additional flags for user
export const CommentIcon = makeIcon(FiMessageSquare, { title: 'Comment' })
export const InfoIcon = makeIcon(FiHelpCircle, { title: 'More information' })
export const AlertIcon = makeIcon(FiAlertTriangle, { title: 'Alert' })

// General UI components
export const ChevronRightIcon = makeIcon(FiChevronRight)
export const CloseIcon = makeIcon(FiX, { title: 'Close' })
export const DragHandleIcon = makeIcon(FiMoreVertical, {
  title: 'Click and drag to reorder',
})
export const NoResultsIcon = makeIcon(FiClipboard, { title: 'Empty section' })

export const LinkIcon = makeIcon(FiLink, { title: 'Link' })
export const ExternalLinkIcon = makeIcon(FiExternalLink, {
  title: 'External link',
})

// Nav
export const BellIcon = makeIcon(FiBell)
export const MenuIcon = makeIcon(FiMenu)
export const HomeIcon = makeIcon(FiHome)
export const EmailIcon = makeIcon(MdEmail)
export const FeedbackIcon = makeIcon(VscFeedback)

// Extension Nav
export const SettingsIcon = makeIcon(FiMenu)
export const AdjustSettingsIcon = makeIcon(VscSettings)
export const SwapModeIcon = makeIcon(IoMdSync)

// Social media
export const TwitterIcon = makeIcon(FaTwitter)
export const FacebookIcon = makeIcon(FaFacebook)
export const InstagramIcon = makeIcon(AiFillInstagram)

// Specific icons - user info
export const UsersIcon = makeIcon(FiUsers)
export const DomainReviewsIcon = makeIcon(FiCompass)
export const MemberJoinedIcon = makeIcon(FiClock)

// Specific icons - homepage
export const ConnectIcon = makeIcon(VscPlug)
export const ShareIcon = makeIcon(FiShare2)
export const LearnIcon = makeIcon(MdSchool)
export const KnowIcon = makeIcon(GiBrainstorm)

// Browsers
export const ChromeIcon = makeIcon(FiChrome, { title: 'Chrome' })
export const FirefoxIcon = makeIcon(FaFirefoxBrowser, { title: 'Firefox' })
export const SafariIcon = makeIcon(SiSafari, { title: 'Safari' })
export const OperaIcon = makeIcon(FaOpera, { title: 'Opera' })
export const EdgeBrowserIcon = makeIcon(GrEdge, { title: 'Edge' })

// Testing...
export const PageReviewsIcon = makeIcon(FiEye)
export const RuleReviewsIcon = makeIcon(FiAperture)
export const TriangleIcon = makeIcon(FiTriangle)
