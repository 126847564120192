import client from './siteClient'
import { uniq, compact } from 'lodash'

// NOTE: should these all be async/await? Or, since that just means they return a promise (which client does), is that unneeded?

export const getCurrentUserJWT = () => client(`auth`, 'POST')

export const startFollowingUser = (userId) => client(`following/${userId}`, 'POST')
export const stopFollowingUser = (userId) => client(`following/${userId}`, 'DELETE')
export const organizeFollowedUser = (userId, tags) =>
  client(`following/${userId}`, 'PUT', { tags: uniq(compact(tags)) })

export const startBlockingUser = (userId) => client(`blocking/${userId}`, 'POST')
export const stopBlockingUser = (userId) => client(`blocking/${userId}`, 'DELETE')

export const loadUserSettings = () => client(`settings`, 'GET')
export const updateUserSettings = (data) => client(`settings`, 'PUT', data)
export const updateUserColor = (color) => client(`settings/color`, 'PUT', { color })
export const setProfileReview = (data) => client(`settings/profile-review`, 'PUT', data)

export const sayHello = (args) => client('hello', 'PUT', args)

export const doReviewJustThePage = async ({ domainKey, pageKey, data }) => {
  console.log('!! START doReviewJustThePage')
  const res = await client(`review/page`, 'PUT', { domainKey, pageKey, data })
  console.log('!! END doReviewJustThePage', res)
  return res
}
export const doReviewJustTheDomain = async ({ domainKey, data }) =>
  await client(`review/domain`, 'PUT', { domainKey, data })
