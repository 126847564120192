import dynamic from 'next/dynamic'
import { useCurrentUser } from 'universal/hooks'
import { cn } from 'common/utils'
import { useHasMounted } from 'common/hooks'
import { Suspense } from 'react'
import { Loader } from 'cc/Loader'

// No need to load the firestoreui stuff if we're not logged out...
const DynamicLoggedOut = dynamic(() => import('./LoggedOut'), { ssr: false })
const DynamicLoggedIn = dynamic(() => import('./LoggedIn'), { ssr: false })

const InnerAuth = ({ onLandingPage }) => {
  const currentUser = useCurrentUser()
  if (typeof currentUser === 'undefined') return null // not yet checked - prevent flash of incorrect state in UI
  if (!currentUser) return <DynamicLoggedOut onLandingPage={onLandingPage} />

  return (
    <DynamicLoggedIn currentUser={currentUser} onLandingPage={onLandingPage} />
  )
}

const NavAuthentication = ({ onLandingPage }) => {
  const hasMounted = useHasMounted()
  return (
    <nav
      className={cn(
        'relative',
        onLandingPage
          ? 'inline font-medium text-blue-600 hover:text-blue-500'
          : 'ml-2 flex justify-end',
      )}
    >
      {!hasMounted ? null : (
        <Suspense fallback={null}>
          <InnerAuth onLandingPage={onLandingPage} />
        </Suspense>
      )}
    </nav>
  )
}

export default NavAuthentication
