import { useMemo, useCallback, useEffect } from 'react'
import { filter } from 'lodash'

// Extracts wrapping Select to return only values (not full options) in onChange && to accept value as an option value (not as an option object)
const useSelectByValue = ({ isMulti, options, onChange, valueAsValue }) => {
  const valueAsOption = useMemo(
    () =>
      isMulti
        ? filter(options || [], ({ value }) => (valueAsValue || []).indexOf(value) !== -1) || []
        : (options || []).find(({ value }) => value === valueAsValue) || null,
    [options, isMulti, valueAsValue],
  )

  const onChangeValueAdapter = useCallback(
    (selectedOption) => {
      const value = isMulti ? (selectedOption || []).map(({ value }) => value) : selectedOption?.value
      onChange && onChange(value)
    },
    [isMulti, onChange],
  )

  return { valueAsOption, onChangeValueAdapter }
}

export default useSelectByValue
