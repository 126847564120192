import { useCallback, useEffect, useState, forwardRef } from 'react'
import CreatableSelect from 'react-select/creatable'
import { uniqBy } from 'lodash'
import { useFormContext } from 'react-hook-form'
import useLocalOptions from './useLocalOptions'
import useSelectByValue from './useSelectByValue'

const CreatableSelectInputByValue = forwardRef(({ onChange, value: valueAsValue, options, isMulti, ...props }, ref) => {
  const { valueAsOption, onChangeValueAdapter } = useSelectByValue({
    isMulti,
    options,
    onChange,
    valueAsValue,
  })

  return (
    <CreatableSelect
      {...props}
      isMulti={isMulti}
      onChange={onChangeValueAdapter}
      value={valueAsOption}
      options={options}
      ref={ref}
    />
  )
})

const defaultCreateOptionFn = (value) => ({ value, label: value })

// So-named because this is a SUB component of the publicly-accessible SelectField. It's wrapped in a FormProvider and it is NOT intended to be called directly.
const CreatableSelectSubfield = forwardRef(
  ({ createOptionFn = defaultCreateOptionFn, options: initialOptions, isMulti, name, ...props }, ref) => {
    const { getValues, setValue } = useFormContext()

    const { localOptions, addLocalOptions } = useLocalOptions({
      initialOptions,
    })

    // We also need to inject it into local options when user creates a new option
    const handleOptionCreation = useCallback(
      (input) => {
        const option = createOptionFn(input)
        addLocalOptions([option])

        // Automatically select the newly-created option (we're assuming we're wrapped in a react-hook-form)
        if (isMulti) {
          setValue(name, [...(getValues(name) || []), option.value])
        } else {
          setValue(name, option.value)
        }
      },
      [isMulti, name, addLocalOptions, createOptionFn, getValues, setValue],
    )

    return (
      <CreatableSelectInputByValue
        name={name}
        {...props}
        options={localOptions}
        isMulti={isMulti}
        onCreateOption={handleOptionCreation}
        inputRef={ref}
      />
    ) // todo confirm ref usage
  },
)

export default CreatableSelectSubfield
