import Link from 'components/Link'
import { cn } from 'common/utils'
import NavAuthentication from 'components/NavAuthentication'
import routes from 'common/routes'
import React from 'react'
import NavLogo from 'cc/NavLogo'
import CollapsiblePrimaryNav from './CollapsiblePrimaryNav'

export const navLinkConfig = [
  { name: 'Views', href: routes.usersPath },
  { name: 'Sites', href: routes.sitesPath },
  { name: 'About', href: routes.aboutPath, inAuthedMenu: true },
  { name: 'Contact', href: routes.contactPath, inAuthedMenu: true },
]

const coreLinks = navLinkConfig.filter(({ inAuthedMenu }) => !inAuthedMenu)

// TODO: efficient way to determine if link should be active (not currently used)
export const NavLink = ({ text, href, className, dark, active = false }) => (
  <Link
    href={href}
    className={cn(
      className,
      !className && 'font-light mr-4 last:mr-0',
      !className &&
        (dark
          ? 'text-gray-700 hover:text-gray-800'
          : 'text-gray-600 hover:text-gray-900'),
      active && 'bg-red-100',
    )}
  >
    {text}
  </Link>
)

const TopNavBar = React.forwardRef(({ className, dark }, ref) => (
  // TODO: why does this shadow disappear on <md sizes?

  <div className={cn(className, 'shadow-md z-10')} ref={ref}>
    <nav
      className={cn(
        dark ? 'bg-gray-500 border-gray-600' : 'bg-gray-50',
        'flex flex-wrap items-center justify-between px-2 py-3 border-b w-full',
      )}
    >
      <NavLogo dark={dark} />

      <div className="hidden sm:block">
        {coreLinks.map(({ name, href }) => (
          <NavLink key={name} href={href} text={name} dark={dark} />
        ))}
      </div>
      <CollapsiblePrimaryNav links={coreLinks} className="sm:hidden" />

      <div className="min-w-24 flex items-center sm:min-w-[200px] justify-end">
        {/* Needs explicit width to avoid jumping when this loads after page has already rendered */}
        <NavAuthentication dark={dark} />
      </div>
    </nav>
  </div>
))

TopNavBar.displayName = 'TopNavBar'
export default TopNavBar
