import config, { prodRoot } from 'common/config'
import { hashtagToString } from 'common/utils'
import { compact, isFunction, startsWith } from 'lodash'

export const SignalHandleIsUidPrefix = 'anon--'

const root = process.env.APP_CONTEXT === 'extension' ? prodRoot : config.siteRootUrl

const makeAbsolute = (path) => (startsWith(path, 'http') ? path : [root, path].join(''))

// Wrapper to ensure not given undefined arguments
const rt =
  (numExpected, routeFn) =>
  (...args) => {
    for (let i = 0; i < numExpected; i++) {
      if (!args[i]) {
        const ex = routeFn(...[...Array(numExpected).keys()])

        console.warn(`Route missing expected argument (index: ${i}) in example: ${ex}`)
        return null
        // throw new Error(
        //   `Route missing expected argument (index: ${i}) in example: ${ex}`,
        // )
      }
    }

    return routeFn(...args)
  }

const routes = {
  root: '/',

  extAuth: '/ext/auth', // Extension auth landing page
  welcome: '/about/welcome', // welcome new user
  about: '/about',
  aboutExtension: '/about/extension',
  termsOfService: '/about/terms',
  privacyPolicy: '/about/privacy',
  contact: '/about/contact',
  faq: '/about/faq',
  users: '/views',
  settings: '/settings',
  sites: `/sites`,
  site: rt(1, (domainKey) => `/site/${domainKey}`),
  hashtag: rt(1, (tag) => `/tagged/${hashtagToString(tag)}`),
  user: rt(1, (user) => {
    if (user) {
      if (user.handle) return `/v/${user.handle}`
      if (user.id) return `/v/${SignalHandleIsUidPrefix}${user.id}`
      throw new Error('Called user route with a non-user object')
    }

    return '/views'
  }),
  userManageReviews: rt(1, (user, domainKey) =>
    compact([`/manage-reviews`, domainKey && `domainKey=${domainKey}`]).join('?'),
  ),

  // // ?? or /domains/foo.com/by/kali ??
  userDomainReview: rt(2, (user, domainKey) => `/v/${user.handle}/reviews/${domainKey}`),
  userDomainPageReview: rt(3, (user, domainKey, pageKey) => `/v/${user.handle}/reviews/${domainKey}/${pageKey}`),
  review: (user, domainKey, pageKey) =>
    pageKey ? routes.userDomainPageReview(user, domainKey, pageKey) : routes.userDomainReview(user, domainKey),
}

const makeRoutesReducer = (lookup, [name, valueOrFn]) => {
  // Avoid errors in extension by skipping generation of _path variants in that context
  if ('extension' !== process.env.APP_CONTEXT) {
    lookup[`${name}Path`] = valueOrFn
  }

  lookup[`${name}Url`] = isFunction(valueOrFn) ? (...args) => makeAbsolute(valueOrFn(...args)) : makeAbsolute(valueOrFn)

  return lookup
}

const hydratedRoutes = Object.entries(routes).reduce(makeRoutesReducer, new Object())

export default hydratedRoutes
